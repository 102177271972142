import { useDispatch, useSelector } from "react-redux";
import { getSystems } from "../../ducks/systems/actions";
import { useEffect } from "react";
import { SystemsList } from "../Systems/SystemsList";
import { selectIsGetSystemsLoading } from "../../ducks/systems/reducer";
import { RouteWrapper } from "../Layout/RouteWrapper";
import { ActiveSystemsList } from "../ActiveSystemsCard/ActiveSystemsCard";

export const SystemsRoute = () => {
  const dispatch = useDispatch();
  const shouldShowLoader = useSelector(selectIsGetSystemsLoading);

  useEffect(() => {
    dispatch(getSystems());
  }, []);

  return (
    <RouteWrapper shouldShowLoader={shouldShowLoader}>
      <ActiveSystemsList />
      <SystemsList />
    </RouteWrapper>
  );
};
