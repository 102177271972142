import React from "react"
import { useDispatch } from "react-redux"
import { hideModal } from "../../ducks/modal/actions"
import clsx from "clsx"
import CloseIcon from "@mui/icons-material/Close"
import styles from "./Modal.module.scss"

export const Modal = ({ children, isDismissable = true, isOpen }) => {
    const dispatch = useDispatch()

    return (
        <div className={clsx(styles.modalOverlay, { [styles.openModal]: isOpen })}>
            <div className={styles.modalWrapper}>
                {isDismissable && (
                    <div className={styles.closeIconWrapper}>
                        <CloseIcon
                            sx={{ color: "black", fontSize: "3rem", padding: "0.5rem", cursor: "pointer" }}
                            onClick={() => {
                                dispatch(hideModal())
                            }}
                        />
                    </div>
                )}
                {children}
            </div>
        </div>
    )
}