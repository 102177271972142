import { InputLabel, MenuItem, Select } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import get from "lodash/get"
import map from "lodash/map"
import { selectAllCustomers } from "../../ducks/customers/reducer"
import { addNewSystem } from "../../ducks/systems/actions"
import { Button } from "../Buttons/Button"
import { Input } from "../Input/Input"
import { Title } from "../Typography/Title"
import { getAllCustomers } from "../../ducks/customers/actions"
import { Subtitle } from "../Typography/Subtitle"
import { apiRequest } from "../../utils/apiRequest"
import { getAllLocations } from "../../ducks/locations/actions"
import { selectAllLocations } from "../../ducks/locations/reducer"
import { Modal } from "../Modal/Modal"
import { selectIsAddNewSystemModalShowing } from "../../ducks/modal/reducer"
import styles from "./Modals.module.scss"

export const AddNewSystemModal = () => {
    const isOpen = useSelector(selectIsAddNewSystemModalShowing)
    const customers = useSelector(selectAllCustomers)
    const locations = useSelector(selectAllLocations)
    const dispatch = useDispatch()

    // customer
    const [customerId, setCustomerId] = useState("")
    // location
    const [locationId, setLocationId] = useState("")
    // config
    const [configId, setConfigId] = useState("")


    useEffect(() => {
        dispatch(getAllCustomers())
        dispatch(getAllLocations())
    }, [])

    const handleAddSystem = () => {
        apiRequest({
            endpoint: "config",
            data: {}
        }).then(addConfigResponse => {
            dispatch(addNewSystem({
                requestBody: {
                    customer_id: customerId,
                    config_id: get(addConfigResponse, "data.config_id"),
                    location_id: locationId
                }
            }))
        })
    }

    return (
        <Modal isOpen={isOpen}>
            <Title>Add New System</Title>
            <InputLabel required id="customer">Customer</InputLabel>
            <Select
                id="customer"
                value={customerId || "placeholder"}
                variant="standard"
                onChange={e => setCustomerId(e.target.value)}
            >
                <MenuItem disabled value="placeholder">
                    <em>Select a customer</em>
                </MenuItem>
                {
                    map(customers, customer => (
                        <MenuItem value={customer.id} key={customer.id}>{customer.name}</MenuItem>
                    ))
                }
            </Select>
            <InputLabel required id="location">Location</InputLabel>
            <Select
                id="location"
                value={locationId || "placeholder"}
                variant="standard"
                onChange={e => setLocationId(e.target.value)}
            >
                <MenuItem disabled value="placeholder">
                    <em>Select a location</em>
                </MenuItem>
                {
                    map(locations, location => (
                        <MenuItem value={location.id} key={location.id}>{location.address_line_one}, {location.address_line_two}<br />{location.city}, {location.state}</MenuItem>
                    ))
                }
            </Select>
            <Button
                additionalClasses={styles.button}
                onClick={handleAddSystem}
            >
                Add System
            </Button>
        </Modal>
    )
}