import { SET_IMAGES_FOR_OPERATION } from "./actions"

const initialState = {
    imagesForSelectedOperation: []
}

export const imagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_IMAGES_FOR_OPERATION:
            return {
                ...state,
                imagesForSelectedOperation: action.images
            }
        default:
            return state
    }
}

export const selectImagesForSelectedOperation = state => {
    return state.images.imagesForSelectedOperation
}