import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import get from "lodash/get"
import { addNewEquipmentForSystem } from "../../ducks/systems/actions"
import { selectSelectedSystem } from "../../ducks/systems/reducer"
import { Button } from "../Buttons/Button"
import { Input } from "../Input/Input"
import { Subtitle } from "../Typography/Subtitle"
import { Title } from "../Typography/Title"
import { selectIsAddNewEquipmentModalShowing } from "../../ducks/modal/reducer"
import { Modal } from "../Modal/Modal"
import styles from "./Modals.module.scss"

export const AddNewEquipmentModal = () => {
    const dispatch = useDispatch()
    const isOpen = useSelector(selectIsAddNewEquipmentModalShowing)
    const system = useSelector(selectSelectedSystem)
    const systemId = get(system, "id")

    const [name, setName] = useState("")
    const [serialNumber, setSerialNumber] = useState("")
    const [versionNumber, setVersionNumber] = useState("")

    const handleAddEquipment = () => {
        dispatch(addNewEquipmentForSystem({
            system_id: systemId,
            name,
            serial_number: serialNumber,
            version_number: versionNumber
        }))
    }

    return (
        <Modal isOpen={isOpen}>
            <Title>Add New Equipment</Title>
            <Subtitle additionalClasses={styles.subtitle}>System ID: {systemId}</Subtitle>
            <Input
                label="Equipment Name"
                id="name"
                required
                value={name}
                onChange={e => setName(e.target.value)}
            />
            <Input
                label="Version Number"
                id="versionNumber"
                required
                value={versionNumber}
                onChange={e => setVersionNumber(e.target.value)}
            />
            <Input
                label="Serial Number"
                id="serialNumber"
                required
                value={serialNumber}
                onChange={e => setSerialNumber(e.target.value)}
            />
            <Button
                additionalClasses={styles.button}
                onClick={handleAddEquipment}
            >
                Add Equipment
            </Button>
        </Modal>
    )
}