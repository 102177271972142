export const SYSTEM_SIDES = Object.freeze({
  DRIVER: "DRIVER",
  PASSENGER: "PASSENGER",
});

export const SYSTEM_SIDE_SUB_NAV_ITEMS = Object.freeze({
  ROBOT: "ROBOT",
  CHANGER: "CHANGER",
  BALANCER: "BALANCER",
  CAMERA: "CAMERA",
  KICKLIFTS: "KICKLIFTS",
  IMAGES: "IMAGES",
});
