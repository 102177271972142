import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Layout } from "./components/Layout/Layout";
import { CustomerRoute } from "./components/Routes/CustomerRoute";
import { CustomersRoute } from "./components/Routes/CustomersRoute";
import { Home } from "./components/Routes/Home";
import { JobRoute } from "./components/Routes/JobRoute";
import { LivestreamRoute } from "./components/Routes/LivestreamRoute";
import { SystemRoute } from "./components/Routes/SystemRoute";
import { SystemStateRoute } from "./components/Routes/SystemStateRoute";
import { SystemsRoute } from "./components/Routes/SystemsRoute";
import { ROUTES } from "./constants/routes";
import { SocketIO } from "./components/SocketIO/SocketIO";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={`${ROUTES.CUSTOMERS}`} element={<CustomersRoute />} />
          <Route
            path={`${ROUTES.CUSTOMERS}/:customerId`}
            element={<CustomerRoute />}
          />
          <Route path={`${ROUTES.SYSTEMS}`} element={<SystemsRoute />} />
          <Route path={`${ROUTES.SYSTEMS}/:id`} element={<SystemRoute />} />
          <Route
            path={`${ROUTES.SYSTEM_STATE}/:id`}
            element={<SystemStateRoute />}
          />
          <Route
            path={`${ROUTES.SYSTEM_STATE}/:id/completed/:jobId`}
            element={<SystemStateRoute />}
          />
          <Route path={`${ROUTES.JOB}/:id`} element={<JobRoute />} />
          <Route path={ROUTES.LIVESTREAM} element={<LivestreamRoute />} />
        </Routes>
      </Layout>
      <SocketIO />
    </BrowserRouter>
  );
}

export default App;
