import React, { useState } from "react"
import { LogInCard } from "./LogInCard"
import { RegisterCard } from "./RegisterCard"
import styles from "./LogInAndRegister.module.scss"

export const LogInScreen = () => {
    const [showRegisterScreen, setShowRegisterScreen] = useState(false)

    return (
        <div className={styles.wrapper}>
            {
                showRegisterScreen ? (
                    <RegisterCard setShowRegisterScreen={setShowRegisterScreen} />
                ) : (
                    <LogInCard setShowRegisterScreen={setShowRegisterScreen} />
                )
            }
        </div>
    )
}