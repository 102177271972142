import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addNewCustomer } from "../../ducks/customers/actions"
import { selectIsAddNewCustomerLoading } from "../../ducks/customers/reducer"
import { selectIsAddNewCustomerModalShowing } from "../../ducks/modal/reducer"
import { Button } from "../Buttons/Button"
import { Input } from "../Input/Input"
import { Modal } from "../Modal/Modal"
import { Title } from "../Typography/Title"
import styles from "./Modals.module.scss"

export const AddNewCustomerModal = () => {
    const dispatch = useDispatch()
    const isOpen = useSelector(selectIsAddNewCustomerModalShowing)
    const isAddNewCustomerLoading = useSelector(selectIsAddNewCustomerLoading)

    const [name, setName] = useState("")
    const [addressLineOne, setAddressLineOne] = useState("")
    const [addressLineTwo, setAddressLineTwo] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zipCode, setZipCode] = useState("")

    const handleAddCustomer = () => {
        dispatch(addNewCustomer({
            requestBody: {
                name,
                address_line_one: addressLineOne,
                address_line_two: addressLineTwo,
                city,
                state,
                postal_code: zipCode,
                country: "United States",
                continent: "North America"
            }
        }))
    }

    return (
        <Modal isOpen={isOpen}>
            <Title>Add New Customer</Title>
            <Input
                label="Company Name"
                id="name"
                required
                value={name}
                onChange={e => setName(e.target.value)}
            />
            <Input
                label="Address"
                id="address"
                value={addressLineOne}
                onChange={e => setAddressLineOne(e.target.value)}
            />
            <Input
                label="Address Line 2"
                id="addressTwo"
                value={addressLineTwo}
                onChange={e => setAddressLineTwo(e.target.value)}
            />
            <Input
                label="City"
                id="city"
                value={city}
                onChange={e => setCity(e.target.value)}
            />
            <Input
                label="State"
                id="state"
                value={state}
                onChange={e => setState(e.target.value)}
            />
            <Input
                label="Zip Code"
                id="zipCode"
                value={zipCode}
                onChange={e => setZipCode(e.target.value)}
            />
            <Button
                additionalClasses={styles.button}
                onClick={handleAddCustomer}
                isLoading={isAddNewCustomerLoading}
            >
                Add Customer
            </Button>
        </Modal>
    )
}