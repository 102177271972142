import { useSelector } from "react-redux"
import get from "lodash/get"
import { SYSTEM_SIDES } from "../../constants/system"
import { selectSystemStateDriverSideChanger, selectSystemStatePassengerSideChanger } from "../../ducks/systems/reducer"
import { Text, TextTypes } from "../Typography/Text"
import { TextWithLabel } from "../Typography/TextWithLabel"
import styles from "./SystemState.module.scss"

export const ChangerInfo = ({ side }) => {
    const changerInfoSelector = side === SYSTEM_SIDES.DRIVER ? selectSystemStateDriverSideChanger : selectSystemStatePassengerSideChanger
    const changerInfo = useSelector(changerInfoSelector)

    return (
        <div className={styles.subNavItemWrapper}>
            <Text type={TextTypes.HEADER_FIVE} additionalClasses={styles.subNavItemTitleOverrides}>Changer</Text>
            <div className={styles.infoSectionWrapper}>
                <TextWithLabel label="Initialized">{get(changerInfo, "initialized")}</TextWithLabel>
                <TextWithLabel label="Online">{get(changerInfo, "online")}</TextWithLabel>
                <TextWithLabel label="Current Wheel Assembly">{get(changerInfo, "current_wheel_assembly")}</TextWithLabel>
                <TextWithLabel label="Completed Command">{get(changerInfo, "completed_command")}</TextWithLabel>
                <TextWithLabel label="New Tire Loaded">{get(changerInfo, "new_tire_loaded")}</TextWithLabel>
                <TextWithLabel label="New Tire Mounted">{get(changerInfo, "new_tire_mounted")}</TextWithLabel>
                <TextWithLabel label="Tire Demounted">{get(changerInfo, "tire_demounted")}</TextWithLabel>
                <TextWithLabel label="Wheel Assembly Loaded">{get(changerInfo, "wheel_assembly_loaded")}</TextWithLabel>
                <TextWithLabel label="Spindle Angle">{get(changerInfo, "spindle_angle")}</TextWithLabel>
            </div>
        </div>
    )
}