import { useSelector } from "react-redux"
import get from "lodash/get"
import map from "lodash/map"
import { selectVehicleDataFromSystemState } from "../../ducks/systems/reducer"
import styles from "./SystemState.module.scss"
import { Card } from "../Card/Card"
import { Text, TextTypes } from "../Typography/Text"
import { translateKey } from "../../utils/translations"

export const VehicleDataCard = () => {
    const vehicleData = useSelector(selectVehicleDataFromSystemState)
    const vehicleDataFieldsToDisplay = ["vin", "year", "make", "model", "sub_model", "nut_bolt_hex", "nut_bolt_torque", "nut_quantity", "wheel_base"]

    return (
        <Card additionalClasses={styles.cardOverrides}>
            <Text type={TextTypes.HEADER_FIVE} additionalClasses={styles.titleOverrides}>Vehicle Data</Text>
            {
                map(vehicleDataFieldsToDisplay, field => (
                    <div className={styles.column} key={field}>
                        <Text type={TextTypes.LABEL}>{translateKey(field)}</Text>
                        <Text type={TextTypes.BODY_ONE} additionalClasses={styles.contentOverrides}>{`${get(vehicleData, field)}`}</Text>
                    </div>
                ))
            }
        </Card>
    )
}