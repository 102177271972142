import clsx from "clsx";
import React from "react";
import get from "lodash/get";
import startsWith from "lodash/startsWith";
import logo from "../../assets/logo.svg";
import { Link } from "../Buttons/Link";
// import ArrowIcon from "@mui/icons-material/EastOutlined"
import ArrowIcon from "@mui/icons-material/KeyboardReturnOutlined";
// import ArrowIcon from "@mui/icons-material/ArrowRightAltOutlined"
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "../../constants/routes";
import styles from "./Nav.module.scss";
import { useSelector } from "react-redux";
import { selectSelectedCustomerInfo } from "../../ducks/customers/reducer";
import { selectSelectedSystemId } from "../../ducks/systems/reducer";

export const Nav = () => {
  const location = useLocation();
  const currentRoute = get(location, "pathname");
  const navigate = useNavigate();

  const selectedCustomer = useSelector(selectSelectedCustomerInfo);
  const selectedSystemId = useSelector(selectSelectedSystemId);

  const shouldShowSelectedCustomer = selectedCustomer && !selectedSystemId;
  const shouldShowSelectedSystem = selectedSystemId;

  return (
    <div className={styles.navWrapper}>
      <div className={styles.logoWrapper}>
        <img alt="Logo" src={logo} className={styles.logo} />
        <p className={styles.logoText}>Admin Portal</p>
      </div>
      <Link
        onClick={() => navigate(ROUTES.HOME)}
        additionalClasses={clsx(
          styles.menuItem,
          currentRoute === "/" && styles.activeItem
        )}
      >
        Home
      </Link>
      <Link
        onClick={() => navigate(ROUTES.CUSTOMERS)}
        additionalClasses={clsx(
          styles.menuItem,
          startsWith(currentRoute, ROUTES.CUSTOMERS) && styles.activeItem
        )}
      >
        Customers
      </Link>
      {shouldShowSelectedCustomer && (
        <div className={styles.selectedItemWrapper}>
          <ArrowIcon sx={{ marginRight: "0.5rem", transform: "scaleX(-1)" }} />
          {get(selectedCustomer, "name")}
        </div>
      )}
      <Link
        onClick={() => navigate(ROUTES.SYSTEMS)}
        additionalClasses={clsx(
          styles.menuItem,
          (startsWith(currentRoute, ROUTES.SYSTEMS) ||
            startsWith(currentRoute, ROUTES.SYSTEM_STATE)) &&
            styles.activeItem
        )}
      >
        Systems
      </Link>
      {shouldShowSelectedSystem && (
        <div
          className={styles.selectedItemWrapper}
          onClick={() => navigate(`${ROUTES.SYSTEMS}/${selectedSystemId}`)}
        >
          <ArrowIcon sx={{ marginRight: "0.5rem", transform: "scaleX(-1)" }} />
          System {selectedSystemId}
        </div>
      )}
    </div>
  );
};
