import clsx from "clsx";
import React, { useState } from "react";
import get from "lodash/get";
import slice from "lodash/slice";
import { Link } from "../Buttons/Link";
import { Card } from "../Card/Card";
import { Table } from "../Table/Table";
import { Title } from "../Typography/Title";
import styles from "./ListCard.module.scss";

export const ListCard = ({
  additionalClasses = {},
  AdditionalContent,
  title,
  tableActions,
  tableProps = {},
  isExpandable = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const { data: dataForTable, ...restTableProps } = tableProps;
  const formattedDataForTable =
    !isExpanded && isExpandable ? slice(dataForTable, 0, 5) : dataForTable;

  return (
    <Card
      additionalClasses={clsx(
        styles.cardOverrides,
        additionalClasses.cardOverrides
      )}
    >
      <div
        className={clsx(styles.titleWrapper, additionalClasses.titleWrapper)}
      >
        <Title additionalClasses={clsx(styles.title, additionalClasses.title)}>
          {title}
        </Title>
      </div>
      {AdditionalContent && <AdditionalContent />}
      <Table
        tableActions={tableActions}
        data={formattedDataForTable}
        {...restTableProps}
      />
      {isExpandable && (
        <Link
          onClick={handleExpandClick}
          additionalClasses={styles.viewMoreLink}
        >
          {isExpanded ? "View Less" : "View More"}
        </Link>
      )}
    </Card>
  );
};
