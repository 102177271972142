import { useState } from "react";
import { PrimarySystemStateCard } from "./PrimarySystemStateCard";
import { SystemStateNav, SYSTEM_STATE_NAV_ITEMS } from "./SystemStateNav";
import { VehicleDataCard } from "./VehicleDataCard";
import { JobProgressCard } from "./JobProgressCard";
import { PositioningAndLiftCard } from "./PositioningAndLiftCard";
import { SystemSide } from "./SystemSide";
import { SYSTEM_SIDES } from "../../constants/system";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import styles from "./SystemState.module.scss";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectSelectedSystemId } from "../../ducks/systems/reducer";
import { ROUTES } from "../../constants/routes";
import { FinalTorqueCard } from "./FinalTorqueCard";
import { AlertsCard } from "./AlertsCard";

export const SystemState = () => {
  const navigate = useNavigate();
  const systemId = useSelector(selectSelectedSystemId);
  const [activeNavigationItem, setActiveNavigationItem] = useState(
    SYSTEM_STATE_NAV_ITEMS.JOB_PROGRESS
  );

  return (
    <>
      <div
        className={styles.viewSystemLink}
        onClick={() => navigate(`${ROUTES.SYSTEMS}/${systemId}`)}
      >
        <ChevronLeft />
        View System
      </div>
      <PrimarySystemStateCard />
      <SystemStateNav
        activeNavigationItem={activeNavigationItem}
        setActiveNavigationItem={setActiveNavigationItem}
      />
      <div className={styles.activeNavigationItemWrapper}>
        {activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.JOB_PROGRESS && (
          <JobProgressCard />
        )}
        {activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.ALERTS && (
          <AlertsCard />
        )}
        {activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.FINAL_TORQUE && (
          <FinalTorqueCard />
        )}
        {activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.VEHICLE_DATA && (
          <VehicleDataCard />
        )}
        {activeNavigationItem ===
          SYSTEM_STATE_NAV_ITEMS.PULL_IN_AND_LIFT_DATA && (
          <PositioningAndLiftCard />
        )}
        {activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.DRIVER_SIDE && (
          <SystemSide side={SYSTEM_SIDES.DRIVER} />
        )}
        {activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.PASSENGER_SIDE && (
          <SystemSide side={SYSTEM_SIDES.PASSENGER} />
        )}
      </div>
    </>
  );
};
