import { Nav } from '../Nav/Nav'
import { Loader } from '../Loader/Loader'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader } from '../../ducks/loading/actions'
import { checkForCookie } from '../../utils/auth'
import { setIsLoggedIn } from '../../ducks/user/actions'
import { selectIsLoggedIn } from '../../ducks/user/reducer'
import { LogInScreen } from '../LogInAndRegister/LogInScreen'
import { Menu } from '../Menu/Menu'
import { ModalsWrapper } from '../Modals/ModalsWrapper'
import styles from './Layout.module.scss'

export const Layout = ({ children }) => {
    const dispatch = useDispatch()
    const isLoggedIn = useSelector(selectIsLoggedIn)

    // on load, check for valid auth token or go to log in
    useEffect(() => {
        dispatch(showLoader())
        const hasAuthCookie = checkForCookie()
        if (hasAuthCookie) {
            dispatch(hideLoader())
            dispatch(setIsLoggedIn(true))
        } else {
            setTimeout(() => {
                dispatch(hideLoader())
                dispatch(setIsLoggedIn(false))
            }, 1000)
        }
    }, [])

    return (
        <>
            <Loader />
            <ModalsWrapper />
            {isLoggedIn ? (
                <div className={styles.mainWrapper}>
                    <Nav />
                    {children}
                </div>
            ) : (
                <LogInScreen />
            )}
        </>
    )
}
