import clsx from "clsx"
import { Text, TextTypes } from "../Typography/Text"
import styles from "./SystemState.module.scss"

export const WheelProgressItem = ({ name, completed, active }) => {
    return (
        <div className={styles.wheelProgressSingleItemWrapper}>
            <div className={clsx(styles.dot, { [styles.completedDot]: completed, [styles.activeDot]: active })} />
            <div className={styles.wheelProgressSingleItemContentWrapper}>
                <Text
                    type={TextTypes.BODY_ONE}
                    additionalClasses={clsx(styles.wheelProgressItemText, {
                        [styles.activeWheelProgressItem]: active,
                        [styles.completeWheelProgressItem]: completed,
                    })}
                >
                    {name}
                </Text>
                {active && (
                    <Text
                        type={TextTypes.BODY_TWO}
                        additionalClasses={styles.wheelProgressItemActiveText}
                    >
                        In Progress
                    </Text>
                )}
                {completed && (
                    <Text
                        type={TextTypes.BODY_TWO}
                        additionalClasses={styles.wheelProgressItemCompleteText}
                    >
                        Complete
                    </Text>
                )}
                {(!completed && !active) && (
                    <Text
                        type={TextTypes.BODY_TWO}
                        additionalClasses={styles.wheelProgressItemNotStartedText}
                    >
                        Not Started
                    </Text>
                )}
            </div>
        </div>
    )
}