import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { logIn } from "../../ducks/user/actions"
import { Button } from "../Buttons/Button"
import { Card } from "../Card/Card"
import { Input } from "../Input/Input"
import { Title } from "../Typography/Title"
import styles from "./LogInAndRegister.module.scss"

export const LogInCard = ({ setShowRegisterScreen }) => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }

    const handleLogIn = (event) => {
        event.preventDefault()
        dispatch(logIn({ email, password }))
    }

    return (
        <Card>
            <Title>
                LOG IN
            </Title>
            <form action="#" className={styles.formWrapper} onSubmit={handleLogIn}>
                <Input label="Email" id="email" type="email" onChange={handleEmailChange} value={email} />
                <Input label="Password" id="password" type="password" onChange={handlePasswordChange} value={password} />
                <Button additionalClasses={styles.button} onClick={handleLogIn}>Log In</Button>
            </form>
            {/* <div className={styles.link} onClick={() => setShowRegisterScreen(true)}>Create Account</div> */}
        </Card>
    )
}