import get from "lodash/get"
import { apiRequest } from "../../utils/apiRequest"

export const SET_LIVESTREAMS = "SET_LIVESTREAMS"
export const SET_ACTIVE_LIVESTREAM = "SET_ACTIVE_LIVESTREAM"

export const setLivestreams = livestreams => {
    return {
        type: SET_LIVESTREAMS,
        livestreams
    }
}

export const setActiveLivestream = livestream => {
    return {
        type: SET_ACTIVE_LIVESTREAM,
        livestream
    }
}

export const getAllLivestreams = () => {
    return dispatch => {
        return apiRequest({
            endpoint: `livestreams`,
            method: "GET"
        })
            .then(getLivestreamsResponse => {
                dispatch(setLivestreams(get(getLivestreamsResponse, "data")))
                dispatch(getLivestream(get(getLivestreamsResponse, "data[0].livestream_id")))
            })
            .catch(error => {
                console.log("Get livestreams error: ", error)
            })
    }
}

export const getLivestream = (livestreamId) => {
    return dispatch => {
        return apiRequest({
            endpoint: `livestreams/${livestreamId}/view`,
            method: "GET"
        })
            .then(getLivestreamResponse => {
                dispatch(setActiveLivestream(get(getLivestreamResponse, "data")))
            })
            .catch(error => {
                console.log("Get livestream error: ", error)
            })
    }
}