import React, { useState } from "react";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import some from "lodash/some";
import values from "lodash/values";
import lowerCase from "lodash/lowerCase";
import clsx from "clsx";
import { Menu, MenuItem } from "@mui/material";
import { Input } from "../Input/Input";
import { Button } from "../Buttons/Button";
import styles from "./Table.module.scss";

const searchData = (item, searchValue) =>
  some(values(item), (value) => {
    if (typeof value === "object") {
      return searchData(value, searchValue);
    }
    return includes(lowerCase(value), lowerCase(searchValue));
  });

export const Table = ({
  data = [],
  headers,
  additionalCellProps = () => ({}),
  additionalClasses = {},
  actions = [],
  keyPreface,
  tableActions,
  hideSearch = false,
  noDataText = "No Data",
}) => {
  const [anchorEls, setAnchorEls] = React.useState({});
  const [searchValue, setSearchValue] = useState("");
  const filteredDataBySearch = filter(data, (item) =>
    searchData(item, searchValue)
  );
  const dataOrder = map(headers, (header) => header.dataKey);

  return (
    <>
      <div className={styles.actionsAndSearchWrapper}>
        {!hideSearch && (
          <Input
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
        )}
        {tableActions && (
          <div className={styles.actionsWrapper}>
            {map(tableActions, (action) => (
              <Button
                additionalClasses={styles.actionButtonOverrides}
                onClick={action.onClick}
              >
                {action.title}
              </Button>
            ))}
          </div>
        )}
      </div>
      {data.length === 0 ? (
        <div
          className="sm:rounded-lg mx-full w-full overflow-x-scroll mt-4 text-gray-500 uppercase text-center p-4"
          style={{ backgroundColor: "#EEEEEE" }}
        >
          No Data Found
        </div>
      ) : (
        <div
          className={`shadow sm:rounded-lg mx-full w-full overflow-x-scroll mt-4 ${
            headers.length > 5 ? "self-start" : "self-center"
          }`}
        >
          <div className="overflow-y-hidden overflow-x-scroll border-gray-200">
            <table className="min-w-full">
              <thead className="px-6" style={{ backgroundColor: "#DADADA" }}>
                <tr>
                  {map(headers, (header, i) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                      key={header.title}
                    >
                      {header.title}
                    </th>
                  ))}
                  {actions.length > 0 && (
                    <th className="px-6 py-3 text-left text-xs font-medium text-neutral-600 uppercase">
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {map(filteredDataBySearch, (item, index) => {
                  const key = keyPreface ? item[keyPreface] : item.id;

                  return (
                    <tr
                      key={`row-${key}`}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      {map(dataOrder, (dataKey, i) => {
                        const { renderValue, ...customCellProps } =
                          additionalCellProps({ item, currentItem: dataKey }) ||
                          {};

                        return (
                          <td
                            className="px-6 py-4 whitespace-nowrap text-gray-900"
                            key={`${dataKey}-${key}`}
                            {...customCellProps}
                          >
                            <span
                              title={`${
                                renderValue ? renderValue() : item[dataKey]
                              }`}
                            >
                              {renderValue ? renderValue() : item[dataKey]}
                            </span>
                          </td>
                        );
                      })}
                      {actions.length === 1 && (
                        <td key={actions[0].title}>
                          <button
                            className={clsx(
                              styles.tableButton,
                              styles.actionsCell
                            )}
                            onClick={() => {
                              window.scrollTo(0, 0);
                              actions[0].onClick(item);
                            }}
                          >
                            {actions[0].title}
                          </button>
                        </td>
                      )}
                      {actions.length > 1 && (
                        <td
                          className={clsx(
                            styles.actionsButton,
                            styles.actionsCell
                          )}
                        >
                          <Button
                            id={key}
                            aria-controls={key}
                            aria-haspopup="true"
                            aria-expanded={
                              Boolean(anchorEls[key]) ? "true" : undefined
                            }
                            onClick={(e) => {
                              setAnchorEls({
                                ...anchorEls,
                                [key]: e.currentTarget,
                              });
                            }}
                          >
                            Actions
                          </Button>
                          <Menu
                            id={key}
                            anchorEl={anchorEls[key]}
                            open={Boolean(anchorEls[key])}
                            onClose={() =>
                              setAnchorEls({ ...anchorEls, [key]: null })
                            }
                            MenuListProps={{
                              "aria-labelledby": key,
                            }}
                            transformOrigin={{
                              vertical: -90,
                              horizontal: 0,
                            }}
                          >
                            <MenuItem disabled>
                              <em>{key}</em>
                            </MenuItem>
                            {map(actions, (action) => (
                              <MenuItem
                                onClick={() => action.onClick(item)}
                                key={action.title}
                              >
                                {action.title}
                              </MenuItem>
                            ))}
                          </Menu>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};
