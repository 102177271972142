import { apiRequest } from "../../utils/apiRequest"

export const SET_ALL_SYSTEM_ERRORS = "SET_ALL_SYSTEM_ERRORS"
export const SET_SYSTEM_ERRORS_FOR_SELECTED_SYSTEM = "SET_SYSTEM_ERRORS_FOR_SELECTED_SYSTEM"
export const SET_ALL_OPERATION_ERRORS = "SET_ALL_OPERATION_ERRORS"
export const SET_ALL_ALARMS = "SET_ALL_ALARMS"

export const setAllSystemErrors = (errors) => {
    return {
        type: SET_ALL_SYSTEM_ERRORS,
        errors
    }
}

export const setSystemErrorsForSelectedSystem = (errors) => {
    return {
        type: SET_SYSTEM_ERRORS_FOR_SELECTED_SYSTEM,
        errors
    }
}

export const setAllOperationErrors = (errors) => {
    return {
        type: SET_ALL_OPERATION_ERRORS,
        errors
    }
}

export const setAllAlarms = (alarms) => {
    return {
        type: SET_ALL_ALARMS,
        alarms
    }
}

export const getAllSystemErrors = () => {
    return dispatch => {
        return apiRequest({
            endpoint: "systems_errors",
            method: "GET"
        })
            .then(getAllSystemsErrorsResponse => {
                dispatch(setAllSystemErrors(getAllSystemsErrorsResponse.data))
            })
            .catch(error => {
                console.log("Get all system errors error: ", error)
            })
    }
}

export const getSystemErrorsForSystem = (systemId) => {
    return dispatch => {
        return apiRequest({
            method: "GET",
            endpoint: `systems/${systemId}/errors`
        })
            .then(getSystemErrorsForSystemResponse => {
                dispatch(setSystemErrorsForSelectedSystem(getSystemErrorsForSystemResponse.data))
            })
            .catch(error => {
                console.log("Get system errors for system error: ", error)
            })
    }
}

export const getAllOperationErrors = () => {
    return dispatch => {
        return apiRequest({
            endpoint: "operations_errors",
            method: "GET"
        })
            .then(getAllOperationsErrorsResponse => {
                dispatch(setAllOperationErrors(getAllOperationsErrorsResponse.data))
            })
            .catch(error => {
                console.log("Get all operations errors error: ", error)
            })
    }
}

export const getAllAlarms = () => {
    return dispatch => {
        return apiRequest({
            endpoint: "alarms",
            method: "GET"
        })
            .then(getAllAlarmsResponse => {
                dispatch(setAllAlarms(getAllAlarmsResponse.data))
            })
            .catch(error => {
                console.log("Get all alarms error: ", error)
            })
    }
}
