import axios from "axios"
import { map } from "lodash"
import get from "lodash/get"
import { apiRequest } from "../../utils/apiRequest"

export const SET_IMAGES_FOR_OPERATION = "SET_IMAGES_FOR_OPERATION"

export const setImagesForOperation = images => {
    return {
        type: SET_IMAGES_FOR_OPERATION,
        images
    }
}

export const getImagesForOperation = (operationId, systemId) => {
    return dispatch => {
        return apiRequest({
            endpoint: `image_urls?operation_id=${operationId}&system_id=${systemId}`,
            method: "GET"
        })
            .then(getImagesResponse => {
                dispatch(setImagesForOperation(get(getImagesResponse, "data")))
            })
            .catch(error => {
                console.log("Get images for operation error: ", error)
            })
    }
}