import { apiRequest } from "../../utils/apiRequest"

export const SET_MACHINE_LEARNING_DETECTIONS_FOR_OPERATION = "SET_MACHINE_LEARNING_DETECTIONS_FOR_OPERATION"

export const setMachineLearningDetectionsForOperation = machineLearningDetections => {
    return {
        type: SET_MACHINE_LEARNING_DETECTIONS_FOR_OPERATION,
        machineLearningDetections
    }
}

export const getMachineLearningDetectionsForOperation = (operationId) => {
    return dispatch => {
        return apiRequest({
            endpoint: `operations/${operationId}/ml_detections`,
            method: "GET"
        })
            .then(getMLDetectionsResponse => {
                dispatch(setMachineLearningDetectionsForOperation(getMLDetectionsResponse.data))
            })
            .catch(error => {
                console.log("Get ml detections responses error: ", error)
            })
    }
}