import { useSelector } from "react-redux";
import get from "lodash/get";
import {
  selectSelectedSystemId,
  selectSystemStateForSelectedSystem,
} from "../../ducks/systems/reducer";
import { Card } from "../Card/Card";
import { Text, TextTypes } from "../Typography/Text";
import styles from "./SystemStatus.module.scss";
import { Button, BUTTON_TYPES } from "../Buttons/Button";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../constants/routes";

export const SystemStatus = () => {
  const params = useParams();
  const systemId = get(params, "id");
  const navigate = useNavigate();
  const systemState = useSelector(selectSystemStateForSelectedSystem);
  const isJobRunning = get(systemState, "job_running");

  return (
    <div className={styles.systemStatusWrapper}>
      <Card additionalClasses={styles.cardOverrides}>
        <div className={styles.column}>
          <Text
            type={TextTypes.HEADER_SIX}
            additionalClasses={styles.titleOverrides}
          >
            Current Status
          </Text>
          <Text
            type={TextTypes.BODY_ONE}
            additionalClasses={styles.contentOverrides}
          >
            {isJobRunning ? "Job In Progress" : "Idle"}
          </Text>
        </div>
        <Button
          additionalClasses={styles.buttonOverrides}
          onClick={() => navigate(`${ROUTES.SYSTEM_STATE}/${systemId}`)}
        >
          View System State
        </Button>
      </Card>
    </div>
  );
};
