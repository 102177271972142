import { combineReducers } from "redux"
import { userReducer } from "./user/reducer"
import { configReducer } from "./configs/reducer"
import { customersReducer } from "./customers/reducer"
import { loadingReducer } from "./loading/reducer"
import { livestreamReducer } from "./livestream/reducer"
import { gripperResponsesReducer } from "./gripperResponses/reducer"
import { locationsReducer } from "./locations/reducer"
import { systemsReducer } from "./systems/reducer"
import { operationsReducer } from "./operations/reducer"
import { modalReducer } from "./modal/reducer"
import { equipmentReducer } from "./equipment/reducer"
import { errorsReducer } from "./errors/reducer"
import { vehiclesReducer } from "./vehicles/reducer"
import { wheelResponsesReducer } from "./wheelResponses/reducer"
import { hubResponsesReducer } from "./hubResponses/reducer"
import { balancerResponsesReducer } from "./balancerResponses/reducer"
import { machineLearningDetectionsReducer } from "./machineLearning/reducer"
import { imagesReducer } from "./images/reducer"

export const rootReducer = combineReducers({
    balancerResponses: balancerResponsesReducer,
    config: configReducer,
    customers: customersReducer,
    equipment: equipmentReducer,
    errors: errorsReducer,
    gripperResponses: gripperResponsesReducer,
    hubResponses: hubResponsesReducer,
    images: imagesReducer,
    livestream: livestreamReducer,
    loading: loadingReducer,
    locations: locationsReducer,
    machineLearning: machineLearningDetectionsReducer,
    modal: modalReducer,
    operations: operationsReducer,
    systems: systemsReducer,
    user: userReducer,
    vehicles: vehiclesReducer,
    wheelResponses: wheelResponsesReducer,
})