import clsx from "clsx";
import { useDispatch } from "react-redux";
import { SYSTEM_SIDE_SUB_NAV_ITEMS } from "../../constants/system";
import styles from "./SystemState.module.scss";

export const SystemSideSubNav = ({
  activeNavigationItem,
  setActiveNavigationItem,
}) => {
  const dispatch = useDispatch();

  const handleNavItemClick = (item) => {
    dispatch(setActiveNavigationItem(item));
  };

  return (
    <div className={styles.navigationWrapper}>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_SIDE_SUB_NAV_ITEMS.ROBOT,
        })}
        onClick={() => handleNavItemClick(SYSTEM_SIDE_SUB_NAV_ITEMS.ROBOT)}
      >
        Robot
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_SIDE_SUB_NAV_ITEMS.IMAGES,
        })}
        onClick={() => handleNavItemClick(SYSTEM_SIDE_SUB_NAV_ITEMS.IMAGES)}
      >
        Images
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_SIDE_SUB_NAV_ITEMS.CHANGER,
        })}
        onClick={() => handleNavItemClick(SYSTEM_SIDE_SUB_NAV_ITEMS.CHANGER)}
      >
        Changer
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_SIDE_SUB_NAV_ITEMS.BALANCER,
        })}
        onClick={() => handleNavItemClick(SYSTEM_SIDE_SUB_NAV_ITEMS.BALANCER)}
      >
        Balancer
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_SIDE_SUB_NAV_ITEMS.CAMERA,
        })}
        onClick={() => handleNavItemClick(SYSTEM_SIDE_SUB_NAV_ITEMS.CAMERA)}
      >
        Camera
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_SIDE_SUB_NAV_ITEMS.KICKLIFTS,
        })}
        onClick={() => handleNavItemClick(SYSTEM_SIDE_SUB_NAV_ITEMS.KICKLIFTS)}
      >
        Kicklifts
      </div>
    </div>
  );
};
