import get from "lodash/get"
import { Text, TextTypes } from "../Typography/Text"
import { WheelProgressItem } from "./WheelProgressItem"
import styles from "./SystemState.module.scss"

export const WheelProgressList = ({ wheelAssembly, title }) => {
    const lugnutsRemoved = get(wheelAssembly, "lugnuts_removed")
    const removedFromVehicle = get(wheelAssembly, "removed_from_vehicle")
    const tireChanged = get(wheelAssembly, "tire_changed")
    const tireBalanced = get(wheelAssembly, "balanced")
    const replacedOnVehicle = get(wheelAssembly, "replaced_on_vehicle")
    const lugnutsReplaced = get(wheelAssembly, "lugnuts_replaced")

    // activeItemIndex is a 1-indexed number to indicate which step we are on. this ensures the ui will look correct and show the appropriate steps are complete. this is needed because when "replaced_on_vehicle" is true, it technically isn't "removed_from_vehicle" anymore, so that field becomes false, but we want the user to see that it was completed.
    let activeItemIndex
    if (lugnutsRemoved) {
        activeItemIndex = 2
    }
    if (removedFromVehicle) {
        activeItemIndex = 3
    }
    if (tireChanged) {
        activeItemIndex = 4
    }
    if (tireBalanced) {
        activeItemIndex = 5
    }
    if (replacedOnVehicle) {
        activeItemIndex = 6
    }
    if (lugnutsReplaced) {
        // entire wheel is complete, 7 will show that all items are complete
        activeItemIndex = 7
    }
    if (!activeItemIndex) {
        // if none are true, we are on the first step (lugnuts removed)
        activeItemIndex = 1
    }

    return (
        <div className={styles.wheelProgressItemWrapper}>
            <Text type={TextTypes.HEADER_SIX} additionalClasses={styles.wheelProgressTitleOverrides}>{title}</Text>
            <WheelProgressItem name={"Lugnut Removal"} active={activeItemIndex === 1} completed={activeItemIndex > 1} />
            <WheelProgressItem name={"Remove From Vehicle"} active={activeItemIndex === 2} completed={activeItemIndex > 2} />
            <WheelProgressItem name={"Tire Change"} active={activeItemIndex === 3} completed={activeItemIndex > 3} />
            <WheelProgressItem name={"Tire Balance"} active={activeItemIndex === 4} completed={activeItemIndex > 4} />
            <WheelProgressItem name={"Replace On Vehicle"} active={activeItemIndex === 5} completed={activeItemIndex > 5} />
            <WheelProgressItem name={"Lugnut Replacement"} active={activeItemIndex === 6} completed={activeItemIndex > 6} />
        </div>
    )
}