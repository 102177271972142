import get from 'lodash/get'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getCustomerInfo, setSelectedCustomer } from '../../ducks/customers/actions'
import { selectIsGetCustomerLoading, selectSelectedCustomerInfo } from '../../ducks/customers/reducer'
import { CustomerInfo } from '../CustomerInfo/CustomerInfo'
import { RouteWrapper } from '../Layout/RouteWrapper'
import { SystemsList } from '../Systems/SystemsList'

export const CustomerRoute = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const customerId = get(params, "customerId")
    const customerInfo = useSelector(selectSelectedCustomerInfo)
    const shouldShowLoader = useSelector(selectIsGetCustomerLoading)

    useEffect(() => {
        if (!customerId) return

        dispatch(getCustomerInfo(customerId))

        return () => {
            dispatch(setSelectedCustomer(null))
        }
    }, [])

    return (
        <RouteWrapper
            shouldShowLoader={shouldShowLoader}
        >
            <CustomerInfo />
            <SystemsList
                queryParams={{
                    customer_id: customerId
                }}
            />
        </RouteWrapper>
    )
}
