import get from 'lodash/get'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { selectIsGetOperationDetailsLoading } from '../../ducks/operations/reducer'
import { RouteWrapper } from '../Layout/RouteWrapper'
import { OperationDetailsList } from '../Operations/OperationDetailsList'
import { SystemInfo } from '../SystemInfo/SystemInfo'

export const JobRoute = () => {
    const params = useParams()
    const jobId = get(params, "id")
    // get loading info
    const shouldShowLoader = useSelector(selectIsGetOperationDetailsLoading)

    return (
        <RouteWrapper
            shouldShowLoader={shouldShowLoader}
        >
            <SystemInfo />
            <OperationDetailsList
                operationId={jobId}
            />
        </RouteWrapper>
    )
}
