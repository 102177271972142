import { SET_CUSTOMERS, SET_SELECTED_CUSTOMER, SET_IS_CUSTOMERS_LOADING, SET_IS_GET_CUSTOMER_LOADING, SET_IS_ADD_NEW_CUSTOMER_LOADING } from "./actions"

const initialState = {
    customers: [],
    selectedCustomer: null,
    isCustomersLoading: false,
    isGetCustomerLoading: false,
    isAddNewCustomerLoading: false
}

export const customersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CUSTOMERS:
            return {
                ...state,
                customers: action.customers
            }
        case SET_SELECTED_CUSTOMER:
            return {
                ...state,
                selectedCustomer: action.customer
            }
        case SET_IS_CUSTOMERS_LOADING:
            return {
                ...state,
                isCustomersLoading: action.isLoading
            }
        case SET_IS_GET_CUSTOMER_LOADING:
            return {
                ...state,
                isGetCustomerLoading: action.isLoading
            }
        case SET_IS_ADD_NEW_CUSTOMER_LOADING:
            return {
                ...state,
                isAddNewCustomerLoading: action.isLoading
            }
        default:
            return state
    }
}

export const selectAllCustomers = state => {
    return state.customers.customers
}

export const selectSelectedCustomerInfo = state => {
    return state.customers.selectedCustomer
}

export const selectIsCustomersLoading = state => {
    return state.customers.isCustomersLoading
}

export const selectIsGetCustomerLoading = state => {
    return state.customers.isGetCustomerLoading
}

export const selectIsAddNewCustomerLoading = state => {
    return state.customers.isAddNewCustomerLoading
}