import { SET_GRIPPER_RESPONSES_FOR_OPERATION } from "./actions"

const initialState = {
    gripperResponses: [],
    grippperResponsesForSelectedOperation: null
}

export const gripperResponsesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GRIPPER_RESPONSES_FOR_OPERATION:
            return {
                ...state,
                grippperResponsesForSelectedOperation: action.gripperResponses
            }
        default:
            return state
    }
}

export const selectGripperResponsesForSelectedOperation = state => {
    return state.gripperResponses.grippperResponsesForSelectedOperation
}