import map from "lodash/map";
import { useSelector } from "react-redux";
import { SYSTEM_SIDES } from "../../constants/system";
import {
  selectSystemStateDriverSideImages,
  selectSystemStatePassengerSideImages,
} from "../../ducks/systems/reducer";
import { Text, TextTypes } from "../Typography/Text";
import styles from "./SystemState.module.scss";

export const Images = ({ side }) => {
  const selectorToUse =
    side === SYSTEM_SIDES.PASSENGER
      ? selectSystemStatePassengerSideImages
      : selectSystemStateDriverSideImages;
  const {
    front_wheel_assembly: {
      clocking: frontClocking,
      hub: frontHub,
      wheelRemoval: frontWheelRemoval,
      lugReplacement: frontLugReplacement,
    },
    rear_wheel_assembly: {
      clocking: rearClocking,
      hub: rearHub,
      wheelRemoval: rearWheelRemoval,
      lugReplacement: rearLugReplacement,
    },
  } = useSelector(selectorToUse);

  return (
    <>
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={styles.subNavItemTitleOverrides}
      >
        Clocking
      </Text>
      <Text
        type={TextTypes.LABEL}
        additionalClasses={styles.systemSideNavLabelOverrides}
      >
        Front
      </Text>
      {map(frontClocking, (image) => (
        <img className={styles.image} key={image} src={image} />
      ))}
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={styles.subNavItemTitleOverrides}
      >
        Hub
      </Text>
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={styles.subNavItemTitleOverrides}
      >
        Wheel Removal
      </Text>
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={styles.subNavItemTitleOverrides}
      >
        Lugnut Replacement
      </Text>
    </>
  );
};
