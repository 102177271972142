import { apiRequest } from "../../utils/apiRequest"

export const SET_GRIPPER_RESPONSES_FOR_OPERATION = "SET_GRIPPER_RESPONSES_FOR_OPERATION"

export const setGripperResponsesForOperation = gripperResponses => {
    return {
        type: SET_GRIPPER_RESPONSES_FOR_OPERATION,
        gripperResponses
    }
}

export const getGripperResponsesForOperation = (operationId) => {
    return dispatch => {
        return apiRequest({
            endpoint: `operations/${operationId}/gripper_responses`,
            method: "GET"
        })
            .then(getGripperResponseResponse => {
                dispatch(setGripperResponsesForOperation(getGripperResponseResponse.data))
            })
            .catch(error => {
                console.log("Get gripper responses error: ", error)
            })
    }
}