import { SET_BALANCER_RESPONSES_FOR_OPERATION } from "./actions"

const initialState = {
    balancerResponses: [],
    balancerResponsesForSelectedOperation: null
}

export const balancerResponsesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BALANCER_RESPONSES_FOR_OPERATION:
            return {
                ...state,
                balancerResponsesForSelectedOperation: action.balancerResponses
            }
        default:
            return state
    }
}

export const selectBalancerResponsesForSelectedOperation = state => {
    return state.balancerResponses.balancerResponsesForSelectedOperation
}