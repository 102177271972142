import get from "lodash/get";
import { SYSTEM_SIDE_SUB_NAV_ITEMS } from "../../constants/system";
import {
  SET_SYSTEM_STATE_DRIVER_SIDE_SUB_NAV_ITEM,
  SET_SYSTEM_STATE_PASSENGER_SIDE_SUB_NAV_ITEM,
  SET_EQUIPMENT_FOR_SELECTED_SYSTEM,
  SET_IS_GET_SYSTEM_LOADING,
  SET_IS_SYSTEMS_LOADING,
  SET_SELECTED_SYSTEM,
  SET_SYSTEMS,
  SET_SYSTEM_STATE,
  SET_SYSTEM_STATE_FOR_SELECTED_SYSTEM,
  SET_ACTIVE_SYSTEMS,
  SET_SELECTED_SYSTEM_ID,
} from "./actions";

const initialState = {
  systems: [],
  activeSystems: [],
  selectedSystemId: null,
  isGetSystemsLoading: false,
  isGetSystemLoading: false,
  selectedSystem: null,
  systemStateForSelectedSystem: null,
  systemStateDriverSideSubNavItem: SYSTEM_SIDE_SUB_NAV_ITEMS.ROBOT,
  systemStatePassengerSideSubNavItem: SYSTEM_SIDE_SUB_NAV_ITEMS.ROBOT,
  equipmentForSelectedSystem: [],
  systemStates: {},
};

export const systemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SYSTEMS:
      return {
        ...state,
        systems: action.systems,
      };
    case SET_SELECTED_SYSTEM_ID:
      return {
        ...state,
        selectedSystemId: action.systemId,
      };
    case SET_ACTIVE_SYSTEMS:
      return {
        ...state,
        activeSystems: action.systems,
      };
    case SET_IS_SYSTEMS_LOADING:
      return {
        ...state,
        isGetSystemsLoading: action.isLoading,
      };
    case SET_IS_GET_SYSTEM_LOADING:
      return {
        ...state,
        isGetSystemLoading: action.isLoading,
      };
    case SET_SELECTED_SYSTEM:
      return {
        ...state,
        selectedSystem: action.system,
      };
    case SET_SYSTEM_STATE:
      return {
        ...state,
        systemStates: {
          [action.systemState.system_id]: action.systemState,
          ...state.systemStates,
        },
      };
    case SET_SYSTEM_STATE_FOR_SELECTED_SYSTEM:
      return {
        ...state,
        systemStateForSelectedSystem: action.systemState,
      };
    case SET_SYSTEM_STATE_DRIVER_SIDE_SUB_NAV_ITEM:
      return {
        ...state,
        systemStateDriverSideSubNavItem: action.selectedItem,
      };
    case SET_SYSTEM_STATE_PASSENGER_SIDE_SUB_NAV_ITEM:
      return {
        ...state,
        systemStatePassengerSideSubNavItem: action.selectedItem,
      };
    case SET_EQUIPMENT_FOR_SELECTED_SYSTEM:
      return {
        ...state,
        equipmentForSelectedSystem: action.equipment,
      };
    default:
      return state;
  }
};

export const selectSystems = (state) => {
  return get(state, "systems.systems");
};

export const selectActiveSystems = (state) => {
  return get(state, "systems.activeSystems");
};

export const selectIsGetSystemsLoading = (state) => {
  return get(state, "systems.isGetSystemsLoading");
};

export const selectIsGetSystemLoading = (state) => {
  return get(state, "systems.isGetSystemLoading");
};

export const selectSelectedSystem = (state) => {
  return get(state, "systems.selectedSystem");
};

export const selectSystemStateById = (state, id) => {
  return get(state, `systems.systemStates.${id}`) || {};
};

export const selectSystemStateForSelectedSystem = (state) => {
  return get(state, "systems.systemStateForSelectedSystem") || {};
};

export const selectAlertsForSelectedSystem = (state) => {
  const allAlerts =
    get(state, "systems.systemStateForSelectedSystem.plc_error_codes") || [];
  const criticalAlerts = get(allAlerts, "critical_errors") || [];
  const nonCriticalAlerts = get(allAlerts, "non_critical_errors") || [];
  const visionAlerts = get(allAlerts, "vision_error_codes") || [];

  return {
    criticalAlerts,
    nonCriticalAlerts,
    visionAlerts,
  };
};

export const selectDriverFrontFromSystemState = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.vehicle.driver_side.front_wheel_assembly"
  );
};

export const selectDriverRearFromSystemState = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.vehicle.driver_side.rear_wheel_assembly"
  );
};

export const selectPassengerFrontFromSystemState = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.vehicle.passenger_side.front_wheel_assembly"
  );
};

export const selectPassengerRearFromSystemState = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.vehicle.passenger_side.rear_wheel_assembly"
  );
};

export const selectVehicleFromSystemState = (state) => {
  return get(state, "systems.systemStateForSelectedSystem.vehicle") || {};
};

export const selectVehicleDataFromSystemState = (state) => {
  return (
    get(
      state,
      "systems.systemStateForSelectedSystem.vehicle.vin_data.part_2_vin_response"
    ) || {}
  );
};

export const selectLiftFromSystemState = (state) => {
  return get(state, "systems.systemStateForSelectedSystem.lift") || {};
};

export const selectSystemStateDriverSideSubNavItem = (state) => {
  return get(state, "systems.systemStateDriverSideSubNavItem");
};

export const selectSystemStatePassengerSideSubNavItem = (state) => {
  return get(state, "systems.systemStatePassengerSideSubNavItem");
};

export const selectSystemStateDriverSideRobot = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.driver_side.robot_subsystem"
  );
};

export const selectSystemStatePassengerSideRobot = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.passenger_side.robot_subsystem"
  );
};

export const selectSystemStateDriverSideChanger = (state) => {
  return get(state, "systems.systemStateForSelectedSystem.driver_side.changer");
};

export const selectSystemStatePassengerSideChanger = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.passenger_side.changer"
  );
};

export const selectSystemStateDriverSideBalancer = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.driver_side.balancer"
  );
};

export const selectSystemStatePassengerSideBalancer = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.passenger_side.balancer"
  );
};

export const selectSystemStateDriverSideCamera = (state) => {
  return get(state, "systems.systemStateForSelectedSystem.driver_side.camera");
};

export const selectSystemStatePassengerSideCamera = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.passenger_side.camera"
  );
};

export const selectSystemStateDriverSideFrontKicklift = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.driver_side.front_kicklift"
  );
};

export const selectSystemStatePassengerSideFrontKicklift = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.passenger_side.front_kicklift"
  );
};

export const selectSystemStateDriverSideRearKicklift = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.driver_side.rear_kicklift"
  );
};

export const selectSystemStatePassengerSideRearKicklift = (state) => {
  return get(
    state,
    "systems.systemStateForSelectedSystem.passenger_side.rear_kicklift"
  );
};

export const selectFinalTorqueValues = (state) => {
  const passengerSide = get(
    state,
    "systems.systemStateForSelectedSystem.vehicle.passenger_side"
  );
  const passengerFrontTorques = get(
    passengerSide,
    "front_wheel_assembly.final_lugnut_torques"
  );
  const passengerRearTorques = get(
    passengerSide,
    "rear_wheel_assembly.final_lugnut_torques"
  );
  const driverSide = get(
    state,
    "systems.systemStateForSelectedSystem.vehicle.driver_side"
  );
  const driverFrontTorques = get(
    driverSide,
    "front_wheel_assembly.final_lugnut_torques"
  );
  const driverRearTorques = get(
    driverSide,
    "rear_wheel_assembly.final_lugnut_torques"
  );
  return {
    passenger_front: passengerFrontTorques,
    passenger_rear: passengerRearTorques,
    driver_front: driverFrontTorques,
    driver_rear: driverRearTorques,
  };
};

export const selectVehicleNutQuantity = (state) =>
  get(
    state,
    "systems.systemStateForSelectedSystem.vehicle.vin_data.part_2_vin_response.nut_quantity"
  ) || 12;

export const selectTorqueSpec = (state) =>
  get(
    state,
    "systems.systemStateForSelectedSystem.vehicle.vin_data.part_2_vin_response.nut_bolt_torque"
  ) || 0;

export const selectSystemStatePassengerSideImages = (state) => {
  const passengerSide = get(
    state,
    "systems.systemStateForSelectedSystem.vehicle.passenger_side"
  );
  const passengerFront = get(passengerSide, "front_wheel_assembly");
  const passengerFrontClocking = get(
    passengerFront,
    "clocking_guidance.images_used"
  );
  const passengerFrontHub = get(passengerFront, "hub_guidance.images_used");
  const passengerFrontLugReplacement = get(
    passengerFront,
    "lugnut_replacement_guidance.images_used"
  );
  const passengerFrontWheelRemoval = get(
    passengerFront,
    "wheel_removal_guidance.images_used"
  );
  const passengerRear = get(passengerSide, "rear_wheel_assembly");
  const passengerRearClocking = get(
    passengerRear,
    "clocking_guidance.images_used"
  );
  const passengerRearHub = get(passengerRear, "hub_guidance.images_used");
  const passengerRearLugReplacement = get(
    passengerRear,
    "lugnut_replacement_guidance.images_used"
  );
  const passengerRearWheelRemoval = get(
    passengerRear,
    "wheel_removal_guidance.images_used"
  );

  return {
    front_wheel_assembly: {
      clocking: passengerFrontClocking,
      hub: passengerFrontHub,
      lugReplacement: passengerFrontLugReplacement,
      wheelRemoval: passengerFrontWheelRemoval,
    },
    rear_wheel_assembly: {
      clocking: passengerRearClocking,
      hub: passengerRearHub,
      lugReplacement: passengerRearLugReplacement,
      wheelRemoval: passengerRearWheelRemoval,
    },
  };
};

export const selectSystemStateDriverSideImages = (state) => {
  const driverSide = get(
    state,
    "systems.systemStateForSelectedSystem.vehicle.driver_side"
  );
  const driverFront = get(driverSide, "front_wheel_assembly");
  const driverFrontClocking = get(driverFront, "clocking_guidance.images_used");
  const driverFrontHub = get(driverFront, "hub_guidance.images_used");
  const driverFrontLugReplacement = get(
    driverFront,
    "lugnut_replacement_guidance.images_used"
  );
  const driverFrontWheelRemoval = get(
    driverFront,
    "wheel_removal_guidance.images_used"
  );
  const driverRear = get(driverSide, "rear_wheel_assembly");
  const driverRearClocking = get(driverRear, "clocking_guidance.images_used");
  const driverRearHub = get(driverRear, "hub_guidance.images_used");
  const driverRearLugReplacement = get(
    driverRear,
    "lugnut_replacement_guidance.images_used"
  );
  const driverRearWheelRemoval = get(
    driverRear,
    "wheel_removal_guidance.images_used"
  );

  return {
    front_wheel_assembly: {
      clocking: driverFrontClocking,
      hub: driverFrontHub,
      lugReplacement: driverFrontLugReplacement,
      wheelRemoval: driverFrontWheelRemoval,
    },
    rear_wheel_assembly: {
      clocking: driverRearClocking,
      hub: driverRearHub,
      lugReplacement: driverRearLugReplacement,
      wheelRemoval: driverRearWheelRemoval,
    },
  };
};

export const selectSelectedSystemId = (state) => {
  return get(state, "systems.selectedSystemId");
};

export const selectCustomerIdFromSelectedSystem = (state) => {
  const selectedSystem = selectSelectedSystem(state);

  return get(selectedSystem, "customer.id");
};

export const selectConfigIdFromSelectedSystem = (state) => {
  const selectedSystem = selectSelectedSystem(state);

  return get(selectedSystem, "config.id");
};

export const selectEquipmentForSelectedSystem = (state) => {
  return get(state, "systems.equipmentForSelectedSystem");
};
