import clsx from "clsx";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { selectSystemStateForSelectedSystem } from "../../ducks/systems/reducer";
import { Card } from "../Card/Card";
import { Text, TextTypes } from "../Typography/Text";
import styles from "./SystemState.module.scss";

export const PrimarySystemStateCard = () => {
  const systemState = useSelector(selectSystemStateForSelectedSystem);

  return (
    <Card
      additionalClasses={clsx(
        styles.cardOverrides,
        styles.mainFieldsCardOverrides
      )}
    >
      <Text
        type={TextTypes.HEADER_FIVE}
        additionalClasses={styles.titleOverrides}
      >
        System State
      </Text>
      <div className={styles.mainFieldsWrapper}>
        <div className={styles.column}>
          <Text type={TextTypes.LABEL}>Current Status</Text>
          <Text
            type={TextTypes.BODY_ONE}
            additionalClasses={styles.contentOverrides}
          >
            {get(systemState, "job_running") ? "Job In Progress" : "Idle"}
          </Text>
        </div>
        <div className={styles.column}>
          <Text type={TextTypes.LABEL}>Current Main Step</Text>
          <Text
            type={TextTypes.BODY_ONE}
            additionalClasses={styles.contentOverrides}
          >{`${get(systemState, "current_main_step")}`}</Text>
        </div>
        <div className={styles.column}>
          <Text type={TextTypes.LABEL}>Current Main Behavior</Text>
          <Text
            type={TextTypes.BODY_ONE}
            additionalClasses={styles.contentOverrides}
          >{`${get(systemState, "current_main_behavior")}`}</Text>
        </div>
      </div>
    </Card>
  );
};
