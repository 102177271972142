import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import get from "lodash/get";
import { selectIsGetSystemLoading } from "../../ducks/systems/reducer";
import {
  getSystem,
  setSelectedSystem,
  setSelectedSystemId,
  setSystemStateForSelectedSystem,
} from "../../ducks/systems/actions";
import { useEffect, useState } from "react";
import { SystemInfo } from "../SystemInfo/SystemInfo";
import { RouteWrapper } from "../Layout/RouteWrapper";
import { SystemState } from "../SystemState/SystemState";
import { SystemStateImage } from "../SystemState/SystemStateImage";
import { SystemStateViewTypes } from "../SystemState/SystemStateViewControls";
import { selectSystemStateForCompletedOperation } from "../../ducks/operations/reducer";

export const SystemStateRoute = () => {
  const [systemStateViewType, setSystemStateViewType] = useState(
    SystemStateViewTypes.RAW
  );
  const params = useParams();
  const dispatch = useDispatch();
  const systemId = get(params, "id");
  const completedJobId = get(params, "jobId");

  const isGetSystemLoading = useSelector(selectIsGetSystemLoading);
  const shouldShowLoader = isGetSystemLoading;

  useEffect(() => {
    dispatch(setSelectedSystemId(systemId));
    dispatch(getSystem(systemId));

    if (completedJobId) {
      const completedState = useSelector((state) =>
        selectSystemStateForCompletedOperation(state, completedJobId)
      );
      dispatch(setSystemStateForSelectedSystem(completedState));
    }

    return () => {
      dispatch(setSelectedSystem(null));
      dispatch(setSelectedSystemId(null));
    };
  }, [systemId]);

  return (
    <RouteWrapper shouldShowLoader={shouldShowLoader}>
      <SystemInfo />
      {systemStateViewType === SystemStateViewTypes.RAW ? (
        <SystemState />
      ) : (
        <SystemStateImage />
      )}
    </RouteWrapper>
  );
};
