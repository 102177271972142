import get from "lodash/get"
import { apiRequest } from "../../utils/apiRequest"
import { hideLoader, showLoader } from "../loading/actions"
import { hideModal } from "../modal/actions"

export const SET_LOCATIONS = "SET_LOCATIONS"
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION"
export const SET_LOCATIONS_FOR_SELECTED_CUSTOMER = "SET_LOCATIONS_FOR_SELECTED_CUSTOMER"

export const setLocations = (locations) => {
    return {
        type: SET_LOCATIONS,
        locations
    }
}

export const setSelectedLocation = (location) => {
    return {
        type: SET_SELECTED_LOCATION,
        location
    }
}

export const setLocationsForSelectedCustomer = (locations) => {
    return {
        type: SET_LOCATIONS_FOR_SELECTED_CUSTOMER,
        locations
    }
}

export const getAllLocations = () => {
    return dispatch => {
        return apiRequest({
            endpoint: "locations",
            method: "GET"
        })
            .then(locationsResponse => {
                dispatch(setLocations(locationsResponse.data))
            })
            .catch(error => {
                console.log("Get locations error: ", error)
            })
    }
}

export const getLocation = (locationId) => {
    return dispatch => {
        return apiRequest({
            endpoint: `locations/${locationId}`,
            method: "GET"
        })
            .then(getLocationResponse => {
                dispatch(setSelectedLocation(getLocationResponse.data))
            })
            .catch(error => {
                console.log("Get locations error: ", error)
            })
    }
}

export const editLocation = (requestBody) => {
    return dispatch => {
        dispatch(showLoader())
        return apiRequest({
            method: "PATCH",
            endpoint: `locations/${requestBody.id}`,
            data: requestBody
        })
            .then(editLocationResponse => {
                dispatch(hideLoader())
                dispatch(getLocation(requestBody.id))
            })
            .catch(error => {
                dispatch(hideLoader())
                console.log("Edit location error: ", error)
            })
    }
}

export const addNewLocation = ({ router, requestBody }) => {
    return dispatch => {
        dispatch(showLoader())
        return apiRequest({
            endpoint: "location",
            data: requestBody
        })
            .then(addLocationResponse => {
                dispatch(hideLoader())
                dispatch(hideModal())
                router.push(`/locations/${get(addLocationResponse, "data.location_id")}`)
            })
            .catch(error => {
                dispatch(hideLoader())
                console.log("Get location error: ", error)
            })
    }
}

export const getLocationsForSelectedCustomer = (customerId) => {
    return dispatch => {
        return apiRequest({
            endpoint: `customers/${customerId}/locations`,
            method: "GET"
        })
            .then(getLocationsForSelectedCustomerResponse => {
                dispatch(setLocationsForSelectedCustomer(get(getLocationsForSelectedCustomerResponse, "data")))
            })
            .catch(error => {
                dispatch(hideLoader())
                console.log("Get location error: ", error)
            })
    }
}