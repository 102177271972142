import { useSelector } from "react-redux";
import floor from "lodash/floor";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";
import map from "lodash/map";
import {
  selectFinalTorqueValues,
  selectVehicleNutQuantity,
  selectTorqueSpec,
} from "../../ducks/systems/reducer";
import { Card } from "../Card/Card";
import { Text, TextTypes } from "../Typography/Text";
import styles from "./SystemState.module.scss";
import clsx from "clsx";

export const TorqueValues = ({ torqueValues }) => {
  const numberOfLugnuts = useSelector(selectVehicleNutQuantity);
  const torqueSpec = useSelector(selectTorqueSpec);

  return (
    <div className={styles.torqueValuesWrapper}>
      {map(torqueValues, (torqueValue, i) => {
        const realIndex = i + 1;
        if (realIndex > numberOfLugnuts) return null;

        const objectId = get(torqueValue, "object_id");
        const torque = get(torqueValue, "current_torque");
        const torquePercentage = get(torqueValue, "current_torque_percentage");

        const isNotTorquedToSpec = torque < torqueSpec;

        return (
          <div
            className={clsx(styles.torqueValueWrapper, {
              [styles.underTorqueSpec]: isNotTorquedToSpec,
            })}
            key={kebabCase(`${objectId}-${torque}`)}
          >
            <Text
              type={TextTypes.BODY_ONE}
              additionalClasses={styles.torqueValueBodyOverrides}
            >
              Lug {realIndex}
            </Text>
            <Text
              type={TextTypes.SUBTITLE_ONE}
              additionalClasses={styles.torqueValueSubtitleOverrides}
            >
              Torque
            </Text>
            <Text
              type={TextTypes.BODY_ONE}
              additionalClasses={styles.torqueValueBodyOverrides}
            >
              {floor(torque, 2)}
            </Text>
            <Text
              type={TextTypes.BODY_ONE}
              additionalClasses={styles.torqueValueBodyOverrides}
            >
              {floor(torquePercentage, 2)}%
            </Text>
          </div>
        );
      })}
    </div>
  );
};

export const FinalTorqueCard = () => {
  const { driver_front, driver_rear, passenger_front, passenger_rear } =
    useSelector(selectFinalTorqueValues);
  const torqueSpec = useSelector(selectTorqueSpec);

  return (
    <Card additionalClasses={styles.jobProgressCardOverrides}>
      <Text type={TextTypes.HEADER_FIVE}>Final Torque Values</Text>
      <Text type={TextTypes.HEADER_SIX}>Torque Spec: {torqueSpec}</Text>
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={styles.headerOverrides}
      >
        Driver Front
      </Text>
      <TorqueValues torqueValues={driver_front} />
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={styles.headerOverrides}
      >
        Driver Rear
      </Text>
      <TorqueValues torqueValues={driver_rear} />
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={styles.headerOverrides}
      >
        Passenger Front
      </Text>
      <TorqueValues torqueValues={passenger_front} />
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={styles.headerOverrides}
      >
        Passenger Rear
      </Text>
      <TorqueValues torqueValues={passenger_rear} />
    </Card>
  );
};
