import React from "react"
import { AddNewUserModal } from "./AddNewUserModal"
import { AddNewSystemModal } from "./AddNewSystemModal"
import { AddNewLocationModal } from "./AddNewLocationModal"
import { AddNewEquipmentModal } from "./AddNewEquipmentModal"
import { AddNewCustomerModal } from "./AddNewCustomerModal"

export const ModalsWrapper = () => {
    return (
        <>
            <AddNewUserModal />
            <AddNewSystemModal />
            <AddNewLocationModal />
            <AddNewEquipmentModal />
            <AddNewCustomerModal />
        </>
    )
}