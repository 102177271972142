import { SET_MACHINE_LEARNING_DETECTIONS_FOR_OPERATION } from "./actions"

const initialState = {
    machineLearningDetections: [],
    machineLearningDetectionsForSelectedOperation: null
}

export const machineLearningDetectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MACHINE_LEARNING_DETECTIONS_FOR_OPERATION:
            return {
                ...state,
                machineLearningDetectionsForSelectedOperation: action.machineLearningDetections
            }
        default:
            return state
    }
}

export const selectMachineLearningDetectionsForSelectedOperation = state => {
    return state.machineLearning.machineLearningDetectionsForSelectedOperation
}