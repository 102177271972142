import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { registerUser } from "../../ducks/user/actions"
import { Card } from "../Card/Card"
import styles from "./LogInAndRegister.module.scss"

export const RegisterCard = ({ setShowRegisterScreen }) => {
    const dispatch = useDispatch()
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value)
    }

    const handleLastNameChange = (e) => {
        setLastName(e.target.value)
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }

    const handleRegister = () => {
        dispatch(registerUser({ firstName, lastName, email, password }))
    }

    const getIsDisabled = () => {
        return !firstName || !lastName || !email || !password
    }

    return (
        <Card>
            <div className={styles.title}>
                Create Account
            </div>
            <label htmlFor="firstName" className={styles.label}>
                First Name
            </label>
            <input required id="firstName" type="text" onChange={handleFirstNameChange} className={styles.input} />
            <label htmlFor="lastName" className={styles.label}>
                Last Name
            </label>
            <input required id="lastName" type="text" onChange={handleLastNameChange} className={styles.input} />
            <label htmlFor="email" className={styles.label}>
                Email
            </label>
            <input required id="email" type="email" onChange={handleEmailChange} className={styles.input} />
            <label htmlFor="password" className={styles.label}>
                Password
            </label>
            <input required id="password" type="password" onChange={handlePasswordChange} className={styles.input} />
            <button className={styles.button} onClick={handleRegister} disabled={getIsDisabled()}>Create Account</button>
            <div className={styles.link} onClick={() => setShowRegisterScreen(false)}>Back To Log In</div>
        </Card>
    )
}