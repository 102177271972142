import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOperationDetails, getOperationsForId } from '../../ducks/operations/actions'
import { selectOperationsDetails, selectOperationsForId } from '../../ducks/operations/reducer'
import get from "lodash/get"
import { ListCard } from '../ListCard/ListCard'
import { Timeline } from '../Timeline/Timeline'
import { Card } from '../Card/Card'
import { Title } from '../Typography/Title'
import { Subtitle } from '../Typography/Subtitle'
import styles from "./Operations.module.scss"

export const OperationDetailsList = ({
    operationId
}) => {
    const dispatch = useDispatch()
    const operationDetails = useSelector(selectOperationsDetails)

    useEffect(() => {
        dispatch(getOperationDetails(operationId))
    }, [])

    return (
        <Card additionalClasses={styles.timelineCardOverrides}>
            <Title>Job Timeline</Title>
            <Subtitle>Job ID: {operationId}</Subtitle>
            {/* <Timeline
                timeline={operationDetails}
            /> */}
        </Card>
    )
}

