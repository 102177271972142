import clsx from "clsx"
import get from "lodash/get"
import React from "react"
import { useSelector } from "react-redux"
import { selectSelectedCustomerInfo } from "../../ducks/customers/reducer"
import { Text, TextTypes } from "../Typography/Text"
import { TextWithLabel } from "../Typography/TextWithLabel"
import styles from "./CustomerInfo.module.scss"

export const CustomerInfo = ({ additionalClasses }) => {
    const customerInfo = useSelector(selectSelectedCustomerInfo)

    return (
        <div className={clsx(styles.customerInfoWrapper, additionalClasses)}>
            <Text type={TextTypes.HEADER_SIX} additionalClasses={styles.titleOverrides}>Customer</Text>
            <TextWithLabel label="Name">{get(customerInfo, "name")}</TextWithLabel>
        </div>
    )
}