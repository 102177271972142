import { useSelector } from "react-redux"
import get from "lodash/get"
import map from "lodash/map"
import keys from "lodash/keys"
import { SYSTEM_SIDES } from "../../constants/system"
import { selectSystemStateDriverSideCamera, selectSystemStatePassengerSideCamera } from "../../ducks/systems/reducer"
import { Text, TextTypes } from "../Typography/Text"
import { TextWithLabel } from "../Typography/TextWithLabel"
import styles from "./SystemState.module.scss"

export const CameraInfo = ({ side }) => {
    const cameraInfoSelector = side === SYSTEM_SIDES.DRIVER ? selectSystemStateDriverSideCamera : selectSystemStatePassengerSideCamera
    const cameraInfo = useSelector(cameraInfoSelector)

    return (
        <div className={styles.subNavItemWrapper}>
            <Text type={TextTypes.HEADER_FIVE} additionalClasses={styles.subNavItemTitleOverrides}>Camera</Text>
            <div className={styles.infoSectionWrapper}>
                {
                    map(keys(cameraInfo), cameraKey => (
                        <TextWithLabel label={cameraKey}>{get(cameraInfo, cameraKey)}</TextWithLabel>
                    ))
                }
            </div>
        </div>
    )
}