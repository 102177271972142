import { InputLabel, MenuItem, Select } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import map from "lodash/map"
import { getAllCustomers } from "../../ducks/customers/actions"
import { selectAllCustomers } from "../../ducks/customers/reducer"
// import { addNewUser } from "../../ducks/users/actions"
import { Button } from "../Buttons/Button"
import { Input } from "../Input/Input"
import { Title } from "../Typography/Title"
import { Modal } from "../Modal/Modal"
import { selectIsAddNewUserModalShowing } from "../../ducks/modal/reducer"
import styles from "./Modals.module.scss"

export const AddNewUserModal = () => {
    const dispatch = useDispatch()
    const isOpen = useSelector(selectIsAddNewUserModalShowing)
    const customers = useSelector(selectAllCustomers)

    const [customerId, setCustomerId] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")

    const handleAddUser = () => {
        // dispatch(addNewUser({
        //     firstName,
        //     lastName,
        //     email,
        //     company
        // }))
    }

    useEffect(() => {
        dispatch(getAllCustomers())
    }, [])

    return (
        <Modal isOpen={isOpen}>
            <Title>Add New User</Title>
            <InputLabel id="customer">CUSTOMER<span className={styles.requiredAsterisk}>*</span></InputLabel>
            <Select
                id="customer"
                value={customerId || "placeholder"}
                variant="standard"
                onChange={e => setCustomerId(e.target.value)}
            >
                <MenuItem disabled value="placeholder">
                    <em>Select a customer</em>
                </MenuItem>
                {
                    map(customers, customer => (
                        <MenuItem value={customer.id} key={customer.id}>{customer.name}</MenuItem>
                    ))
                }
            </Select>
            <Input
                label="First Name"
                id="firstName"
                required
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
            />
            <Input
                label="Last Name"
                id="lastName"
                required
                value={lastName}
                onChange={e => setLastName(e.target.value)}
            />
            <Input
                label="Email"
                id="email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <Button
                additionalClasses={styles.button}
                onClick={handleAddUser}
            >
                Add User
            </Button>
        </Modal>
    )
}