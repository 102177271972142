import filter from "lodash/filter";
import get from "lodash/get";
import {
  SET_OPERATIONS,
  SET_IS_GET_OPERATIONS_LOADING,
  SET_OPERATION_DETAILS,
  SET_IS_GET_OPERATION_DETAILS_LOADING,
  SET_COMPLETED_OPERATIONS,
  SET_IS_GET_COMPLETED_OPERATIONS_LOADING,
} from "./actions";

const initialState = {
  operations: [],
  completedOperations: [],
  isGetCompletedOperationsLoading: false,
  isGetOperationsLoading: false,
  operationDetails: null,
  isGetOperationDetailsLoading: false,
};

export const operationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPERATIONS:
      return {
        ...state,
        operations: action.operations,
      };
    case SET_COMPLETED_OPERATIONS:
      return {
        ...state,
        completedOperations: action.completedOperations,
      };
    case SET_IS_GET_COMPLETED_OPERATIONS_LOADING:
      return {
        ...state,
        isGetCompletedOperationsLoading: action.isLoading,
      };
    case SET_IS_GET_OPERATIONS_LOADING:
      return {
        ...state,
        isGetOperationsLoading: action.isLoading,
      };
    case SET_OPERATION_DETAILS:
      return {
        ...state,
        operationDetails: action.operationDetails,
      };
    case SET_IS_GET_OPERATION_DETAILS_LOADING:
      return {
        ...state,
        isGetOperationDetailsLoading: action.operationDetails,
      };
    default:
      return state;
  }
};

export const selectOperationsForId = (state) => {
  return state.operations.operations;
};

export const selectOperationsDetails = (state) => {
  return state.operations.operationDetails || [];
};

export const selectIsGetOperationsLoading = (state) => {
  return state.operations.isGetOperationsLoading;
};

export const selectIsGetOperationDetailsLoading = (state) => {
  return state.operations.isGetOperationDetailsLoading;
};

export const selectCompletedOperations = (state) => {
  return state.operations.completedOperations;
};

export const selectIsGetCompletedOperationsLoading = (state) => {
  return state.operations.isGetCompletedOperationsLoading;
};

export const selectSystemStateForCompletedOperation = (state, operationId) => {
  const operations = selectCompletedOperations(state);
  const operation = filter(
    operations,
    (operation) => get(operation, "vehicle.vehicle_job_id") === operationId
  )[0];

  return operation;
};
