import { apiRequest } from "../../utils/apiRequest"

export const SET_ALL_EQUIPMENT = "SET_ALL_EQUIPMENT"

export const setAllEquipment = (equipment) => {
    return {
        type: SET_ALL_EQUIPMENT,
        equipment
    }
}

export const getAllEquipment = () => {
    return dispatch => {
        return apiRequest({
            endpoint: "equipment",
            data: {}
        })
            .then(equipmentResponse => {
                dispatch(setAllEquipment(equipmentResponse.data))
            })
            .catch(error => {
                console.log("Get all equipment error: ", error)
            })
    }
}