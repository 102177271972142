import React from "react";
import keys from "lodash/keys";
import map from "lodash/map";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ROUTES } from "../../constants/routes";
import { selectActiveSystems } from "../../ducks/systems/reducer";
import { ListCard } from "../ListCard/ListCard";
import styles from "./ActiveSystemsCard.module.scss";

export const ActiveSystemsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeSystems = useSelector(selectActiveSystems);
  const activeSystemsFormattedForTable = map(activeSystems, (system) => {
    const key = keys(system)[0];
    return {
      key,
      id: key,
      name: system[key],
    };
  });

  return (
    <ListCard
      title="Online Systems"
      tableProps={{
        headers: [{ title: "ID", dataKey: "name", width: 4 }],
        data: activeSystemsFormattedForTable,
        actions: [
          {
            title: "View System",
            onClick: (item) => {
              navigate(`${ROUTES.SYSTEMS}/${item.name}`);
            },
          },
        ],
        hideSearch: true,
        noDataText: "No Online Systems",
      }}
      isExpandable={true}
      additionalClasses={{
        cardOverrides: styles.cardOverrides,
        titleWrapper: styles.titleOverrides,
      }}
    />
  );
};
