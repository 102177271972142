import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompletedJobsForId } from "../../ducks/operations/actions";
import {
  selectIsGetCompletedOperationsLoading,
  selectCompletedOperations,
} from "../../ducks/operations/reducer";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import { ListCard } from "../ListCard/ListCard";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../constants/routes";
import styles from "./Operations.module.scss";

export const OperationsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const systemId = get(params, "id");
  const operations = useSelector(selectCompletedOperations);
  const isLoading = useSelector(selectIsGetCompletedOperationsLoading);

  useEffect(() => {
    if (!systemId) return;

    dispatch(getCompletedJobsForId(systemId));
  }, [systemId]);

  const sortedData = sortBy(operations, (item) =>
    get(item, "vehicle.job_end_timestamp")
  ).reverse();

  return (
    <ListCard
      additionalClasses={{
        cardOverrides: styles.cardOverrides,
      }}
      title="Jobs"
      tableProps={{
        headers: [
          { title: "Job ID", dataKey: "operation_id", width: 16 },
          { title: "Date", dataKey: "unix_time", width: 12 },
          { title: "Status", dataKey: "status", width: 12 },
        ],
        data: sortedData,
        keyPreface: "operation_id",
        additionalCellProps: ({ currentItem, item }) => {
          if (currentItem === "unix_time") {
            const timeStamp = get(item, "vehicle.job_end_timestamp") || "";
            // const splitTimeStamp = timeStamp.split("_");
            // const formattedTimeStamp = `${splitTimeStamp[0]} ${
            //   splitTimeStamp[1].split("-")[0]
            // }`;

            return {
              renderValue: () => (
                <>
                  {new Date(timeStamp).toLocaleDateString("en-US", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </>
              ),
            };
          }
          if (currentItem === "status") {
            return {
              renderValue: () => "Completed",
            };
          }
          if (currentItem === "operation_id") {
            return {
              renderValue: () => get(item, "vehicle.vehicle_job_id"),
            };
          }
        },
        actions: [
          {
            id: "finalState",
            title: "View Final State",
            onClick: (item) => {
              navigate(
                `${ROUTES.SYSTEM_STATE}/${systemId}/completed/${get(
                  item,
                  "vehicle.vehicle_job_id"
                )}`
              );
            },
          },
        ],
      }}
      isLoading={isLoading}
    />
  );
};
