import { useSelector } from "react-redux"
import { selectDriverFrontFromSystemState, selectDriverRearFromSystemState, selectPassengerFrontFromSystemState, selectPassengerRearFromSystemState, selectSystemStateForSelectedSystem } from "../../ducks/systems/reducer"
import { Card } from "../Card/Card"
import { Text, TextTypes } from "../Typography/Text"
import { WheelProgressList } from "./WheelProgressList"
import { Car } from "./CarSVG"
import styles from "./SystemState.module.scss"

export const JobProgressCard = () => {
    const driverFront = useSelector(selectDriverFrontFromSystemState)
    const driverRear = useSelector(selectDriverRearFromSystemState)
    const passengerFront = useSelector(selectPassengerFrontFromSystemState)
    const passengerRear = useSelector(selectPassengerRearFromSystemState)

    return (
        <Card additionalClasses={styles.jobProgressCardOverrides}>
            <Text type={TextTypes.HEADER_FIVE} additionalClasses={styles.titleOverrides}>Job Progress</Text>
            <div className={styles.jobProgressCardContentWrapper}>
                <div className={styles.jobProgressCardColumn}>
                    <WheelProgressList wheelAssembly={driverFront} title={"Driver Front"} />
                    <WheelProgressList wheelAssembly={driverRear} title={"Driver Rear"} />
                </div>
                <Car />
                <div className={styles.jobProgressCardColumn}>
                    <WheelProgressList wheelAssembly={passengerFront} title={"Passenger Front"} />
                    <WheelProgressList wheelAssembly={passengerRear} title={"Passenger Rear"} />
                </div>
            </div>
        </Card>
    )
}