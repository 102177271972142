import clsx from "clsx"
import React from "react"
import { Text, TextTypes } from "./Text"
import styles from "./Typography.module.scss"

export const Title = ({
    additionalClasses,
    children
}) => {
    return (
        <Text additionalClasses={additionalClasses} type={TextTypes.HEADER_FIVE}>{children}</Text>
    )
}