import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addNewLocation } from "../../ducks/locations/actions"
import { selectIsAddNewLocationModalShowing } from "../../ducks/modal/reducer"
import { Button } from "../Buttons/Button"
import { Input } from "../Input/Input"
import { Modal } from "../Modal/Modal"
import { Title } from "../Typography/Title"
import styles from "./Modals.module.scss"

export const AddNewLocationModal = () => {
    const isOpen = useSelector(selectIsAddNewLocationModalShowing)
    const dispatch = useDispatch()

    const [addressLineOne, setAddressLineOne] = useState("")
    const [addressLineTwo, setAddressLineTwo] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zipCode, setZipCode] = useState("")

    const handleAddLocation = () => {
        dispatch(addNewLocation({
            requestBody: {
                address_line_one: addressLineOne,
                address_line_two: addressLineTwo,
                city,
                state,
                postal_code: zipCode,
                country: "United States",
                continent: "North America"
            }
        }))
    }

    return (
        <Modal isOpen={isOpen}>
            <Title>Add New Location</Title>
            <Input
                label="Address"
                id="address"
                value={addressLineOne}
                onChange={e => setAddressLineOne(e.target.value)}
            />
            <Input
                label="Address Line 2"
                id="addressTwo"
                value={addressLineTwo}
                onChange={e => setAddressLineTwo(e.target.value)}
            />
            <Input
                label="City"
                id="city"
                value={city}
                onChange={e => setCity(e.target.value)}
            />
            <Input
                label="State"
                id="state"
                value={state}
                onChange={e => setState(e.target.value)}
            />
            <Input
                label="Zip Code"
                id="zipCode"
                value={zipCode}
                onChange={e => setZipCode(e.target.value)}
            />
            <Button
                additionalClasses={styles.button}
                onClick={handleAddLocation}
            >
                Add Location
            </Button>
        </Modal>
    )
}