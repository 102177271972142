import { useEffect } from "react";
import every from "lodash/every";
import filter from "lodash/filter";
import includes from "lodash/includes";
import get from "lodash/get";
import values from "lodash/values";
import { socket } from "../../utils/socket";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSystems,
  setSystemStateForSelectedSystem,
} from "../../ducks/systems/actions";
import { selectSelectedSystemId } from "../../ducks/systems/reducer";
import { useLocation } from "react-router";

const fileName = "Components/SocketIO/SocketIO";

const parseSocketMessage = (socketMessage) => {
  try {
    return JSON.parse(socketMessage);
  } catch {
    console.log("error with parsing");
    return {};
  }
};

export const SocketIO = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isViewingCompletedJobState = includes(
    get(location, "pathname"),
    "completed"
  );
  const selectedSystemId = useSelector(selectSelectedSystemId);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      console.log("connected");
    });

    socket.on("disconnect", () => {
      console.log("disconnected");
    });

    socket.on("local_server_status", (data) => {
      console.log("local_server_status: ", data);
      // dispatch(setActiveSystems(data));
      const serversFilteredWithoutUIObject = filter(data, (item) =>
        every(values(item), (value) => value !== "ui")
      );

      dispatch(setActiveSystems(serversFilteredWithoutUIObject));
    });

    socket.on("job_status", (data) => {
      console.log("job_status: ", data);
    });

    socket.on("system_state", (data) => {
      console.log(
        "system_state: ",
        data,
        "ss:",
        `${selectedSystemId}`,
        get(data, "system_id"),
        !isViewingCompletedJobState
      );
      if (
        `${selectedSystemId}` === get(data, "system_id") &&
        !isViewingCompletedJobState
      ) {
        console.log("dispatching system state for selected system.");
        dispatch(setSystemStateForSelectedSystem(data));
      }
    });
  }, [socket]);

  return null;
};
