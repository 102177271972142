import get from "lodash/get";
import reduce from "lodash/reduce";
import { allSystems } from "../../mockData/mockSystems-all";
import { apiRequest } from "../../utils/apiRequest";
import { hideLoader, showLoader } from "../loading/actions";
import { hideModal } from "../modal/actions";

export const SET_SYSTEMS = "SET_SYSTEMS";
export const SET_SELECTED_SYSTEM_ID = "SET_SELECTED_SYSTEM_ID";
export const SET_ACTIVE_SYSTEMS = "SET_ACTIVE_SYSTEMS";
export const SET_IS_SYSTEMS_LOADING = "SET_IS_SYSTEMS_LOADING";
export const SET_IS_GET_SYSTEM_LOADING = "SET_IS_GET_SYSTEM_LOADING";
export const SET_SELECTED_SYSTEM = "SET_SELECTED_SYSTEM";
export const SET_SYSTEM_STATE = "SET_SYSTEM_STATE";
export const SET_SYSTEM_STATE_FOR_SELECTED_SYSTEM =
  "SET_SYSTEM_STATE_FOR_SELECTED_SYSTEM";
export const SET_SYSTEM_STATE_DRIVER_SIDE_SUB_NAV_ITEM =
  "SET_SYSTEM_STATE_DRIVER_SIDE_SUB_NAV_ITEM";
export const SET_SYSTEM_STATE_PASSENGER_SIDE_SUB_NAV_ITEM =
  "SET_SYSTEM_STATE_PASSENGER_SIDE_SUB_NAV_ITEM";
export const SET_EQUIPMENT_FOR_SELECTED_SYSTEM =
  "SET_EQUIPMENT_FOR_SELECTED_SYSTEM";

export const setSystems = (systems) => {
  return {
    type: SET_SYSTEMS,
    systems,
  };
};

export const setActiveSystems = (systems) => {
  return {
    type: SET_ACTIVE_SYSTEMS,
    systems,
  };
};

export const setIsSystemsLoading = (isLoading) => {
  return {
    type: SET_IS_SYSTEMS_LOADING,
    isLoading,
  };
};

export const setIsGetSystemLoading = (isLoading) => {
  return {
    type: SET_IS_GET_SYSTEM_LOADING,
    isLoading,
  };
};

export const setSelectedSystem = (system) => {
  return {
    type: SET_SELECTED_SYSTEM,
    system,
  };
};

export const setSelectedSystemId = (systemId) => {
  return {
    type: SET_SELECTED_SYSTEM_ID,
    systemId,
  };
};

export const setSystemState = (systemState) => {
  return {
    type: SET_SYSTEM_STATE,
    systemState,
  };
};

export const setSystemStateForSelectedSystem = (systemState) => {
  return {
    type: SET_SYSTEM_STATE_FOR_SELECTED_SYSTEM,
    systemState,
  };
};

export const setSystemStateDriverSideSubNavItem = (selectedItem) => {
  return {
    type: SET_SYSTEM_STATE_DRIVER_SIDE_SUB_NAV_ITEM,
    selectedItem,
  };
};

export const setSystemStatePassengerSideSubNavItem = (selectedItem) => {
  return {
    type: SET_SYSTEM_STATE_PASSENGER_SIDE_SUB_NAV_ITEM,
    selectedItem,
  };
};

export const setEquipment = (equipment) => {
  return {
    type: SET_EQUIPMENT_FOR_SELECTED_SYSTEM,
    equipment,
  };
};

export const addNewEquipmentForSystem = (data) => {
  return (dispatch) => {
    return apiRequest({
      method: "POST",
      endpoint: `systems/${get(data, "system_id")}/equipment`,
      data,
    })
      .then((addEquipmentResponse) => {
        dispatch(hideModal());
        dispatch(getEquipmentForSystem(get(data, "system_id")));
      })
      .catch((error) => {
        console.log("Add equipment error: ", error);
      });
  };
};

export const getEquipmentForSystem = (systemId) => {
  return (dispatch) => {
    return apiRequest({
      method: "GET",
      endpoint: `system/${systemId}/equipment`,
    })
      .then((equipmentResponse) => {
        dispatch(setEquipment(equipmentResponse.data));
      })
      .catch((error) => {
        console.log("Get equipment error: ", error);
      });
  };
};

export const getSystem = (id) => {
  return (dispatch) => {
    dispatch(setIsGetSystemLoading(true));
    return apiRequest({
      method: "GET",
      endpoint: `systems/${id}`,
    })
      .then((system) => {
        dispatch(setIsGetSystemLoading(false));
        dispatch(setSelectedSystem(system.data));
      })
      .catch((error) => {
        dispatch(setIsGetSystemLoading(false));
        console.log("Get systems error: ", error);
      });
  };
};

export const getSystemState = (id) => {
  return (dispatch) => {
    return apiRequest({
      method: "GET",
      endpoint: `system_state/${id}`,
    })
      .then((systemState) => {
        dispatch(setSystemStateForSelectedSystem(systemState.data));
      })
      .catch((error) => {
        console.log("Get system state error: ", error);
      });
  };
};

export const getSystems = (params = {}) => {
  const queryString = reduce(
    params,
    (result, value, key) => {
      return result === "" ? `?${key}=${value}` : `${result}&${key}=${value}`;
    },
    ""
  );

  return (dispatch) => {
    dispatch(setIsSystemsLoading(true));
    return apiRequest({
      method: "GET",
      endpoint: `systems${queryString}`,
    })
      .then((systems) => {
        dispatch(setIsSystemsLoading(false));
        dispatch(setSystems(systems.data));
      })
      .catch((error) => {
        dispatch(setIsSystemsLoading(false));
        console.log("Get systems error: ", error);
      });
  };
};

export const addNewSystem = ({ requestBody, router }) => {
  return (dispatch) => {
    dispatch(showLoader());
    return apiRequest({
      endpoint: "system",
      data: requestBody,
    })
      .then((addSystemResponse) => {
        dispatch(hideLoader());
        dispatch(hideModal());
        router.push(`/systems/${get(addSystemResponse, "data.system_id")}`);
      })
      .catch((error) => {
        dispatch(hideLoader());
        console.log("Add new system error: ", error);
      });
  };
};
