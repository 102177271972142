import { apiRequest } from "../../utils/apiRequest"

export const SET_BALANCER_RESPONSES_FOR_OPERATION = "SET_BALANCER_RESPONSES_FOR_OPERATION"

export const setBalancerResponsesForOperation = balancerResponses => {
    return {
        type: SET_BALANCER_RESPONSES_FOR_OPERATION,
        balancerResponses
    }
}

export const getBalancerResponsesForOperation = (operationId) => {
    return dispatch => {
        return apiRequest({
            endpoint: `operations/${operationId}/balancer_responses`,
            method: "GET"
        })
            .then(getBalancerResponsesResponse => {
                dispatch(setBalancerResponsesForOperation(getBalancerResponsesResponse.data))
            })
            .catch(error => {
                console.log("Get balancer responses error: ", error)
            })
    }
}