
import ListIcon from "@mui/icons-material/ListAlt"
import CarIcon from "@mui/icons-material/CarRepair"
import styles from "./SystemState.module.scss"
import clsx from "clsx"

export const SystemStateViewTypes = Object.freeze({
    IMAGE: "IMAGE",
    RAW: "RAW"
})

export const SystemStateViewControls = ({ type, setType }) => {
    return (
        <div className={styles.viewControlsWrapper}>
            <div
                className={clsx(styles.controlButtonWrapper, { [styles.activeControlButtonWrapper]: type === SystemStateViewTypes.RAW })}
                onClick={() => setType(SystemStateViewTypes.RAW)}
            >
                <ListIcon />
            </div>
            <div
                className={clsx(styles.controlButtonWrapper, { [styles.activeControlButtonWrapper]: type === SystemStateViewTypes.IMAGE })}
                onClick={() => setType(SystemStateViewTypes.IMAGE)}
            >
                <CarIcon />
            </div>
        </div>
    )
}