import get from "lodash/get"
import { customers } from "../../mockData/mockCustomers"
import { apiRequest } from "../../utils/apiRequest"
import { hideLoader, showLoader } from "../loading/actions"
import { hideModal } from "../modal/actions"

export const SET_CUSTOMERS = "SET_CUSTOMERS"
export const SET_SELECTED_CUSTOMER = "SET_SELECTED_CUSTOMER"
export const SET_IS_CUSTOMERS_LOADING = "SET_IS_CUSTOMERS_LOADING"
export const SET_IS_GET_CUSTOMER_LOADING = "SET_IS_GET_CUSTOMER_LOADING"
export const SET_IS_ADD_NEW_CUSTOMER_LOADING = "SET_IS_ADD_NEW_CUSTOMER_LOADING"

export const setCustomers = (customers) => {
    return {
        type: SET_CUSTOMERS,
        customers
    }
}

export const setSelectedCustomer = (customer) => {
    return {
        type: SET_SELECTED_CUSTOMER,
        customer
    }
}

export const setIsCustomersLoading = (isLoading) => {
    return {
        type: SET_IS_CUSTOMERS_LOADING,
        isLoading
    }
}

export const setIsGetCustomerLoading = (isLoading) => {
    return {
        type: SET_IS_GET_CUSTOMER_LOADING,
        isLoading
    }
}

export const setIsAddNewCustomerLoading = (isLoading) => {
    return {
        type: SET_IS_ADD_NEW_CUSTOMER_LOADING,
        isLoading
    }
}

export const getAllCustomers = () => {
    return dispatch => {
        dispatch(setIsCustomersLoading(true))
        return apiRequest({
            endpoint: "customers",
            method: "GET"
        })
            .then(customersResponse => {
                dispatch(setIsCustomersLoading(false))
                dispatch(setCustomers(customersResponse.data))
            })
            .catch(error => {
                dispatch(setIsCustomersLoading(false))
                console.log("Get customers error: ", error)
            })
    }
}

// export const getAllCustomers = () => {
//     const stringifiedObject = JSON.stringify({})
//     const encodedObject = encodeURI(stringifiedObject)

//     return dispatch => {
//         return apiRequest({
//             endpoint: `/customers?data=${encodedObject}`,
//             method: "GET"
//         })
//             .then(customersResponse => {
//                 dispatch(setCustomers(customersResponse.data))
//             })
//             .catch(error => {
//                 console.log("Get customers error: ", error)
//             })
//     }
// }

export const addNewCustomer = ({ requestBody }) => {
    return dispatch => {
        dispatch(setIsAddNewCustomerLoading(true))
        return apiRequest({
            endpoint: "customer",
            data: requestBody
        })
            .then(addCustomerResponse => {
                dispatch(setIsAddNewCustomerLoading(false))
                dispatch(hideModal())
                router.push(`/customers/${get(addCustomerResponse, "data.customer_id")}`)
            })
            .catch(error => {
                dispatch(setIsAddNewCustomerLoading(false))
                console.log("Get customers error: ", error)
            })
    }
}

export const editCustomer = (requestBody) => {
    return dispatch => {
        return apiRequest({
            method: "PATCH",
            endpoint: `customers/${requestBody.id}`,
            data: requestBody
        })
            .then(editCustomerResponse => {
                dispatch(getCustomerInfo(requestBody.id))
            })
            .catch(error => {
                console.log("Edit customers error: ", error)
            })
    }
}

export const getCustomerInfo = (customerId) => {
    return dispatch => {
        dispatch(setIsGetCustomerLoading(true))
        return apiRequest({
            method: "GET",
            endpoint: `customers/${customerId}`
        })
            .then(getCustomerInfoResponse => {
                dispatch(setIsGetCustomerLoading(false))
                dispatch(setSelectedCustomer(getCustomerInfoResponse.data))
            })
            .catch(error => {
                dispatch(setIsGetCustomerLoading(false))
                console.log("Get customer info error: ", error)
            })
    }
}