import { CircularProgress } from "@mui/material"
import clsx from "clsx"
import styles from "./Layout.module.scss"

export const RouteWrapper = ({
    additionalClasses = {},
    children,
    shouldShowLoader = false
}) => {
    return (
        <div className={clsx(styles.routeWrapper)}>
            {
                shouldShowLoader && (
                    <div className={styles.loadingWrapper}>
                        <CircularProgress size={60} />
                    </div>
                )
            }
            <div className={clsx(styles.contentWrapper, additionalClasses.contentWrapper)}>
                {children}
            </div>
        </div>
    )
}