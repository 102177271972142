import axios from "axios";
import { getCookie } from "./auth";

export const apiRequest = ({
  endpoint = "/",
  method = "POST",
  data,
  version = "v1",
}) => {
  const token = getCookie();

  return axios({
    url: `${process.env.REACT_APP_API_IP_ADDRESS}/${endpoint}`,
    method,
    data,
    headers: {
      Authorization: token,
    },
  });
};
