import { SET_HUB_RESPONSES_FOR_OPERATION } from "./actions"

const initialState = {
    hubResponses: [],
    hubResponsesForSelectedOperation: null
}

export const hubResponsesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HUB_RESPONSES_FOR_OPERATION:
            return {
                ...state,
                hubResponsesForSelectedOperation: action.hubResponses
            }
        default:
            return state
    }
}

export const selectHubResponsesForSelectedOperation = state => {
    return state.hubResponses.hubResponsesForSelectedOperation
}