import clsx from "clsx"
import React from "react"
import styles from "./Typography.module.scss"

export const Subtitle = ({
    additionalClasses,
    children
}) => {
    return (
        <div className={clsx(styles.subtitle, additionalClasses)}>{children}</div>
    )
}