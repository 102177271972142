import React from "react"
import { useSelector } from 'react-redux'
import { selectIsLoading } from '../../ducks/loading/reducer'
import { LoadingLogo } from "./LoadingLogo"
import styles from "./Loader.module.scss"

export const Loader = () => {
    const isLoading = useSelector(selectIsLoading)

    if (isLoading) {
        return (
            <div
                className={styles.loaderWrapper}
            >
                <LoadingLogo />
                <div className={styles.loadingText}>LOADING</div>
            </div>
        )
    }

    return null
}