const translations = Object.freeze({
    hunter_balancer_driver: "Hunter Balancer (Driver Side)",
    hunter_balancer_passenger: "Hunter Balancer (Passenger Side)",
    hunter_changer_driver: "Hunter Changer (Driver Side)",
    hunter_changer_passenger: "Hunter Changer (Passenger Side)",
    zivid_driver: "Zivid (Driver Side)",
    zivid_passenger: "Zivid (Passenger Side)",
    sub_model: "Sub Model",
    nut_bolt_hex: "Nut Bolt Hex (Socket Size)",
    nut_bolt_torque: "Nut Bolt Torque",
    nut_quantity: "Nut Quantity",
    wheel_base: "Wheel Base"
})

export const translateKey = (key) => {
    return translations[key] || key
}
