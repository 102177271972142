import axios from "axios";
import { apiRequest } from "../../utils/apiRequest";
import { setCookie } from "../../utils/auth";

export const SET_USER = "SET_USER";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_IS_LOGGED_IN = "SET_IS_LOGGED_IN";

export const setUser = (user) => {
  return {
    type: SET_USER,
    user,
  };
};

export const setAllUsers = (users) => {
  return {
    type: SET_ALL_USERS,
    users,
  };
};

export const setIsLoggedIn = (isLoggedIn) => {
  return {
    type: SET_IS_LOGGED_IN,
    isLoggedIn,
  };
};

export const getAllUsers = () => {
  return (dispatch) => {
    return apiRequest({
      method: "GET",
      endpoint: `user`,
    })
      .then((usersResponse) => {
        dispatch(setAllUsers(usersResponse.data));
      })
      .catch((error) => {
        console.log("Get all users error: ", error);
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    return apiRequest({
      method: "GET",
      endpoint: `user/me`,
    })
      .then((userResponse) => {
        dispatch(setUser(userResponse));
        dispatch(setIsLoggedIn(true));
      })
      .catch((error) => {
        console.log("Get user error: ", error);
      });
  };
};

export const logIn = ({ email, password }) => {
  const formData = new FormData();
  formData.set("username", email);
  formData.set("password", password);
  return (dispatch) => {
    return axios({
      url: `${process.env.REACT_APP_API_IP_ADDRESS}/auth/jwt/login`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((logInResponse) => {
        setCookie(logInResponse.data.access_token);
        dispatch(getUser());
      })
      .catch((error) => {
        console.log("Log in error: ", error);
      });
  };
};

export const registerUser = ({ email, password, firstName, lastName }) => {
  return (dispatch) => {
    return axios({
      url: `${process.env.REACT_APP_API_IP_ADDRESS}/auth/register`,
      method: "POST",
      data: {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        is_superuser: true,
      },
    })
      .then((registerUserResponse) => {
        console.log({ registerUserResponse });
        // dispatch(getUser(email))
      })
      .catch((error) => {
        console.log("Log in error: ", error);
      });
  };
};
