import clsx from "clsx";
import styles from "./SystemState.module.scss";

export const SYSTEM_STATE_NAV_ITEMS = Object.freeze({
  JOB_PROGRESS: "JOB_PROGRESS",
  ALERTS: "ALERTS",
  DRIVER_SIDE: "DRIVER_SIDE",
  PASSENGER_SIDE: "PASSENGER_SIDE",
  VEHICLE_DATA: "VEHICLE_DATA",
  PULL_IN_AND_LIFT_DATA: "PULL_IN_AND_LIFT_DATA",
});

export const SystemStateNav = ({
  activeNavigationItem,
  setActiveNavigationItem,
}) => {
  return (
    <div className={styles.navigationWrapper}>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.JOB_PROGRESS,
        })}
        onClick={() =>
          setActiveNavigationItem(SYSTEM_STATE_NAV_ITEMS.JOB_PROGRESS)
        }
      >
        Job Progress
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.ALERTS,
        })}
        onClick={() => setActiveNavigationItem(SYSTEM_STATE_NAV_ITEMS.ALERTS)}
      >
        Alerts
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.DRIVER_SIDE,
        })}
        onClick={() =>
          setActiveNavigationItem(SYSTEM_STATE_NAV_ITEMS.DRIVER_SIDE)
        }
      >
        Driver Side
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.PASSENGER_SIDE,
        })}
        onClick={() =>
          setActiveNavigationItem(SYSTEM_STATE_NAV_ITEMS.PASSENGER_SIDE)
        }
      >
        Passenger Side
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.VEHICLE_DATA,
        })}
        onClick={() =>
          setActiveNavigationItem(SYSTEM_STATE_NAV_ITEMS.VEHICLE_DATA)
        }
      >
        Vehicle Data
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem ===
            SYSTEM_STATE_NAV_ITEMS.PULL_IN_AND_LIFT_DATA,
        })}
        onClick={() =>
          setActiveNavigationItem(SYSTEM_STATE_NAV_ITEMS.PULL_IN_AND_LIFT_DATA)
        }
      >
        Positioning and Lift Data
      </div>
      <div
        className={clsx(styles.navigationItem, {
          [styles.activeNavigationItem]:
            activeNavigationItem === SYSTEM_STATE_NAV_ITEMS.FINAL_TORQUE,
        })}
        onClick={() =>
          setActiveNavigationItem(SYSTEM_STATE_NAV_ITEMS.FINAL_TORQUE)
        }
      >
        Final Torque
      </div>
    </div>
  );
};
