import clsx from "clsx";
import get from "lodash/get";
import map from "lodash/map";
import { Text, TextTypes } from "../Typography/Text";
import styles from "./Alert.module.scss";

export const Alert = ({ alert }) => {
  const id = get(alert, "unique_id");
  const severity = get(alert, "group_id");
  const isCriticalSeverity = severity !== 0 && severity !== 1;
  const alertTitle = get(alert, "title");
  const alertObjectId = get(alert, "source_object_id");
  const desciption = get(alert, "description");
  const instructions = get(alert, "instructions");
  const actions = get(alert, "actions") || [];

  return (
    <div
      className={clsx(styles.alarmWrapper, {
        [styles.nonCriticalAlarm]: !isCriticalSeverity,
      })}
    >
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={styles.alarmTitleOverrides}
      >
        {alertTitle}
      </Text>
      {desciption && (
        <>
          <Text
            type={TextTypes.SUBTITLE_ONE}
            additionalClasses={styles.alarmSubtitleOverrides}
          >
            Description
          </Text>
          <Text
            type={TextTypes.BODY_ONE}
            additionalClasses={styles.alarmContentOverrides}
          >
            {desciption}
          </Text>
        </>
      )}
      {instructions && (
        <>
          <Text
            type={TextTypes.SUBTITLE_ONE}
            additionalClasses={styles.alarmSubtitleOverrides}
          >
            Instructions
          </Text>
          <Text
            type={TextTypes.BODY_ONE}
            additionalClasses={styles.alarmContentOverrides}
          >
            {instructions}
          </Text>
        </>
      )}
      {actions.length > 0 && (
        <>
          <Text
            type={TextTypes.SUBTITLE_ONE}
            additionalClasses={styles.alarmSubtitleOverrides}
          >
            Actions
          </Text>
          {map(actions, (action) => (
            <div className={styles.action}>{get(action, "title")}</div>
          ))}
        </>
      )}
    </div>
  );
};
