import Hls from 'hls.js'
import get from 'lodash/get'
import filter from 'lodash/filter'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllLivestreams, getLivestream } from '../../ducks/livestream/actions'
import { selectActiveLivestreamId, selectActiveLivestreamUrl, selectAllLivestreams } from '../../ducks/livestream/reducer'
import { Subtitle } from '../Typography/Subtitle'
import { RouteWrapper } from '../Layout/RouteWrapper'
import styles from "./LivestreamRoute.module.scss"

export const LivestreamRoute = () => {
    const dispatch = useDispatch()
    const livestreams = useSelector(selectAllLivestreams)
    const activeLivestreamId = useSelector(selectActiveLivestreamId)
    const activeLivestreamUrl = useSelector(selectActiveLivestreamUrl)

    useEffect(() => {
        dispatch(getAllLivestreams())
    }, [])

    const videoRef = useRef(null);

    useEffect(() => {
        if (!activeLivestreamUrl) return

        let hls;
        if (videoRef.current) {
            const video = videoRef.current;

            if (video.canPlayType("application/vnd.apple.mpegurl")) {
                // Some browers (safari and ie edge) support HLS natively
                video.src = activeLivestreamUrl;
            } else if (Hls.isSupported()) {
                // This will run in all other modern browsers
                hls = new Hls();
                hls.loadSource(activeLivestreamUrl);
                hls.attachMedia(video);
            } else {
                console.error("This is a legacy browser that doesn't support MSE");
            }
        }

        return () => {
            if (hls) {
                hls.destroy();
            }
        };
    }, [videoRef, activeLivestreamUrl]);

    const inactiveLivestreams = filter(livestreams, stream => stream.livestream_id !== activeLivestreamId)

    return (
        <RouteWrapper>
            <video
                controls
                ref={videoRef}
                className={styles.livestreamPlayer}
            />
            <Subtitle additionalClasses={styles.subtitle}>Other Streams</Subtitle>
            <div className={styles.inactiveStreamsWrapper}>
                {
                    map(inactiveLivestreams, stream => (
                        <div className={styles.inactiveStream} key={get(stream.livestream_id)} onClick={() => dispatch(getLivestream(get(stream, "livestream_id")))}>{startCase(stream.camera_position)}</div>
                    ))
                }
            </div>
        </RouteWrapper>
    )
}
