import { apiRequest } from "../../utils/apiRequest"

export const SET_HUB_RESPONSES_FOR_OPERATION = "SET_HUB_RESPONSES_FOR_OPERATION"

export const setHubResponsesForOperation = hubResponses => {
    return {
        type: SET_HUB_RESPONSES_FOR_OPERATION,
        hubResponses
    }
}

export const getHubResponsesForOperation = (operationId) => {
    return dispatch => {
        return apiRequest({
            endpoint: `operations/${operationId}/hub_responses`,
            method: "GET"
        })
            .then(getHubResponseResponse => {
                dispatch(setHubResponsesForOperation(getHubResponseResponse.data))
            })
            .catch(error => {
                console.log("Get hub responses error: ", error)
            })
    }
}