import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MODALS } from "../../constants/modals";
import { ROUTES } from "../../constants/routes";
import { getAllCustomers } from "../../ducks/customers/actions";
import {
  selectAllCustomers,
  selectIsCustomersLoading,
} from "../../ducks/customers/reducer";
import { showModal } from "../../ducks/modal/actions";
import { ListCard } from "../ListCard/ListCard";

export const CustomersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customers = useSelector(selectAllCustomers);
  const isCustomersLoading = useSelector(selectIsCustomersLoading);

  useEffect(() => {
    dispatch(getAllCustomers());
  }, []);

  const handleAddNewCustomer = () => {
    dispatch(showModal(MODALS.ADD_NEW_CUSTOMER));
  };

  return (
    <ListCard
      title="Customers"
      tableActions={[
        {
          title: "Add New Customer",
          onClick: handleAddNewCustomer,
        },
      ]}
      tableProps={{
        headers: [
          { title: "ID", dataKey: "id", width: 4 },
          { title: "Name", dataKey: "name", width: 20 },
        ],
        data: customers,
        actions: [
          {
            title: "View Customer",
            onClick: (item) => navigate(`${ROUTES.CUSTOMERS}/${item.id}`),
          },
        ],
      }}
      isLoading={isCustomersLoading}
    />
  );
};
