import { apiRequest } from "../../utils/apiRequest"

export const SET_WHEEL_RESPONSE_FOR_OPERATION = "SET_WHEEL_RESPONSE_FOR_OPERATION"

export const setWheelResponseForOperation = wheelResponse => {
    return {
        type: SET_WHEEL_RESPONSE_FOR_OPERATION,
        wheelResponse
    }
}

export const getWheelResponseForOperation = (operationId) => {
    return dispatch => {
        return apiRequest({
            endpoint: `operations/${operationId}/wheel_responses`,
            method: "GET"
        })
            .then(getWheelResponseResponse => {
                dispatch(setWheelResponseForOperation(getWheelResponseResponse.data))
            })
            .catch(error => {
                console.log("Get operations error: ", error)
            })
    }
}