import { CircularProgress } from "@mui/material"
import clsx from "clsx"
import styles from "./Buttons.module.scss"

export const BUTTON_TYPES = Object.freeze({
    PRIMARY: "primary",
    SECONDARY: "secondary",
    NEGATIVE: "negative",
    NEUTRAL: "neutral",
    POSITIVE: "positive",
})

export const Button = ({ additionalClasses, onClick, children, isNavButton, isLoading, isDisabled = false, type = BUTTON_TYPES.PRIMARY }) => {
    return (
        <button
            disabled={isDisabled}
            onClick={onClick}
            className={`${additionalClasses} ${clsx(styles.button, styles[type], {
                [styles.disabled]: isDisabled,
                [styles.navButton]: isNavButton
            })}`}>
            {children}
            { isLoading && (
                <div className={styles.loadingWrapper}>
                    <CircularProgress size={"2rem"} />
                </div>
            )}
        </button>
    )
}