import clsx from "clsx"
import styles from "./Typography.module.scss"

export const TextWithLabel = ({
    additionalClasses = {},
    children,
    label
}) => {
    return (
        <div className={styles.column}>
            <div className={clsx(styles.label, additionalClasses.labelOverrides)}>{label}</div>
            <div className={clsx(styles.bodyOne, styles.uppercase, additionalClasses.contentOverrides)}>{`${children}`}</div>
        </div>
    )
}