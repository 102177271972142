import { useSelector } from "react-redux";
import get from "lodash/get";
import {
  selectSystemStateDriverSideSubNavItem,
  selectSystemStateForSelectedSystem,
  selectSystemStatePassengerSideSubNavItem,
} from "../../ducks/systems/reducer";
import { Card } from "../Card/Card";
import { Text, TextTypes } from "../Typography/Text";
import {
  SYSTEM_SIDES,
  SYSTEM_SIDE_SUB_NAV_ITEMS,
} from "../../constants/system";
import {
  setSystemStateDriverSideSubNavItem,
  setSystemStatePassengerSideSubNavItem,
} from "../../ducks/systems/actions";
import { SystemSideSubNav } from "./SystemSideSubNav";
import { RobotInfo } from "./RobotInfo";
import clsx from "clsx";
import styles from "./SystemState.module.scss";
import { ChangerInfo } from "./ChangerInfo";
import { BalancerInfo } from "./BalancerInfo";
import { CameraInfo } from "./CameraInfo";
import { KickliftsInfo } from "./KickliftsInfo";
import { Images } from "./Images";

export const SystemSide = ({ side }) => {
  const systemSideKey =
    side === SYSTEM_SIDES.DRIVER ? "driver_side" : "passenger_side";
  const navSetter =
    side === SYSTEM_SIDES.DRIVER
      ? setSystemStateDriverSideSubNavItem
      : setSystemStatePassengerSideSubNavItem;
  const systemSideNavSelector =
    side === SYSTEM_SIDES.DRIVER
      ? selectSystemStateDriverSideSubNavItem
      : selectSystemStatePassengerSideSubNavItem;
  const selectedNavItem = useSelector(systemSideNavSelector);
  const systemState = useSelector(selectSystemStateForSelectedSystem);
  const systemSide = get(systemState, systemSideKey);
  const sequence = get(systemSide, "sequence");

  return (
    <Card
      additionalClasses={clsx(
        styles.cardOverrides,
        styles.systemSideCardOverrides
      )}
    >
      <Text
        type={TextTypes.HEADER_FIVE}
        additionalClasses={styles.titleOverrides}
      >
        {side} Side
      </Text>
      <div className={styles.systemSideMainFieldsWrapper}>
        <div className={styles.column}>
          <Text type={TextTypes.LABEL}>Current Behavior</Text>
          <Text
            type={TextTypes.BODY_ONE}
            additionalClasses={styles.contentOverrides}
          >{`${get(sequence, "current_behavior")}`}</Text>
        </div>
        <div className={styles.column}>
          <Text type={TextTypes.LABEL}>Current Step</Text>
          <Text
            type={TextTypes.BODY_ONE}
            additionalClasses={styles.contentOverrides}
          >{`${get(sequence, "current_step")}`}</Text>
        </div>
      </div>
      <div className={styles.hr} />
      <Text
        type={TextTypes.LABEL}
        additionalClasses={styles.systemSideNavLabelOverrides}
      >
        COMPONENTS
      </Text>
      <SystemSideSubNav
        activeNavigationItem={selectedNavItem}
        setActiveNavigationItem={navSetter}
      />
      <div className={styles.activeNavigationItemWrapper}>
        {selectedNavItem === SYSTEM_SIDE_SUB_NAV_ITEMS.ROBOT && (
          <RobotInfo side={side} />
        )}
        {selectedNavItem === SYSTEM_SIDE_SUB_NAV_ITEMS.IMAGES && (
          <Images side={side} />
        )}
        {selectedNavItem === SYSTEM_SIDE_SUB_NAV_ITEMS.CHANGER && (
          <ChangerInfo side={side} />
        )}
        {selectedNavItem === SYSTEM_SIDE_SUB_NAV_ITEMS.BALANCER && (
          <BalancerInfo side={side} />
        )}
        {selectedNavItem === SYSTEM_SIDE_SUB_NAV_ITEMS.CAMERA && (
          <CameraInfo side={side} />
        )}
        {selectedNavItem === SYSTEM_SIDE_SUB_NAV_ITEMS.KICKLIFTS && (
          <KickliftsInfo side={side} />
        )}
      </div>
    </Card>
  );
};
