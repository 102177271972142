import { apiRequest } from "../../utils/apiRequest"

export const SET_VEHICLE_DETAILS_FOR_OPERATION = "SET_VEHICLE_DETAILS_FOR_OPERATION"

export const setVehicleDetailsForOperation = vehicleDetails => {
    return {
        type: SET_VEHICLE_DETAILS_FOR_OPERATION,
        vehicleDetails
    }
}

export const getVehicleForOperation = (operationId) => {
    return dispatch => {
        return apiRequest({
            method: "GET",
            endpoint: `operations/${operationId}/vehicle/`,
        })
            .then(getVehicleResponse => {
                dispatch(setVehicleDetailsForOperation(getVehicleResponse.data))
            })
            .catch(error => {
                console.log("Get operations error: ", error)
            })
    }
}