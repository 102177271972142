import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import startsWith from "lodash/startsWith";
import { selectSelectedSystem } from "../../ducks/systems/reducer";
import { useLocation } from "react-router";
import { Text, TextTypes } from "../Typography/Text";
import { TextWithLabel } from "../Typography/TextWithLabel";
import { ROUTES } from "../../constants/routes";
import styles from "./SystemInfo.module.scss";
import { SystemStateViewControls } from "../SystemState/SystemStateViewControls";

export const SystemInfo = ({ additionalClasses }) => {
  const systemInfo = useSelector(selectSelectedSystem);
  const location = useLocation();
  const currentRoute = get(location, "pathname");

  return (
    <div className={clsx(styles.systemInfoWrapper, additionalClasses)}>
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={styles.titleOverrides}
      >
        System Info
      </Text>
      <TextWithLabel label="System Id">{get(systemInfo, "id")}</TextWithLabel>
      <TextWithLabel label="Customer">
        {get(systemInfo, "customer.name")}
      </TextWithLabel>
      <TextWithLabel label="Location">{`${get(
        systemInfo,
        "location.city"
      )}, ${get(systemInfo, "location.state")}`}</TextWithLabel>
      <TextWithLabel label="Version">
        {get(systemInfo, "version")}
      </TextWithLabel>
    </div>
  );
};
