export const customers = [
    {
        address_line_one: "777 W Pinnacle Peak Rd",
        address_line_two: "Suite B-105",
        city: "Phoenix",
        continent: "North America",
        country: "United States",
        id: 64,
        name: "Discount Tire",
        phone_number: null,
        postal_code: "85027",
        state: "Arizona",
    },
    {
        address_line_one: "3502 N Holly Ave",
        address_line_two: "",
        city: "Lubbock",
        continent: "North America",
        country: "United States",
        id: 23,
        name: "Jurgen's Tire Co.",
        phone_number: null,
        postal_code: "79403",
        state: "Texas",
    },
    {
        address_line_one: "635 SW Gage Blvd",
        address_line_two: "",
        city: "Topeka",
        continent: "North America",
        country: "United States",
        id: 47,
        name: "Stenson's Auto Shop",
        phone_number: null,
        postal_code: "60606",
        state: "Kansas",
    },
]