import { SET_USER, SET_IS_LOGGED_IN, SET_ALL_USERS } from "./actions"
import get from "lodash/get"

const initialState = {
    user: null,
    isLoggedIn: false,
    users: []
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.user
            }
        case SET_IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn
            }
        case SET_ALL_USERS:
            return {
                ...state,
                users: action.users
            }
        default:
            return state
    }
}

export const selectIsLoggedIn = state => {
    return get(state, "user.isLoggedIn")
}
