import get from "lodash/get"
import { apiRequest } from "../../utils/apiRequest"

export const SET_CONFIG = "SET_CONFIG"

export const setConfig = (config) => {
    return {
        type: SET_CONFIG,
        config
    }
}

export const getConfig = (configId) => {
    return dispatch => {
        return apiRequest({
            endpoint: `configs/${configId}`,
            method: "GET"
        })
            .then(getConfigResponse => {
                dispatch(setConfig(getConfigResponse.data))
            })
            .catch(error => {
                console.log("Get config error: ", error)
            })
    }
}

export const editConfig = (config) => {
    return dispatch => {
        return apiRequest({
            endpoint: `config/${get(config, "id")}`,
            method: "PATCH",
            data: config
        })
            .then(editConfigResponse => {
                dispatch(getConfig(get(config, "id")))
            })
            .catch(error => {
                console.log("Edit config error: ", error)
            })
    }
}