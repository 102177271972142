import clsx from "clsx"
import React from "react"
import styles from "./Input.module.scss"

export const Input = ({
    id,
    onChange,
    label,
    required = false,
    type = "text",
    value = "",
    isReadOnly = false,
    placeholder = ""
}) => {

    return (
        <>
            { label && (
                <label htmlFor={id} className={clsx(styles.label, required && styles.required)}>
                    {label}
                </label>
            )
            }
            <input readOnly={isReadOnly} required={required} id={id} type={type} onChange={onChange} className={clsx(styles.input, isReadOnly && styles.readOnlyInput)} placeholder={isReadOnly ? "" : placeholder} value={value} />
        </>
    )
}