import { SET_WHEEL_RESPONSE_FOR_OPERATION } from "./actions"

const initialState = {
    wheelResponses: [],
    wheelResponseForSelectedOperation: null
}

export const wheelResponsesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WHEEL_RESPONSE_FOR_OPERATION:
            return {
                ...state,
                wheelResponseForSelectedOperation: action.wheelResponse
            }
        default:
            return state
    }
}

export const selectWheelResponseForSelectedOperation = state => {
    return state.wheelResponses.wheelResponseForSelectedOperation
}