import { SET_ALL_ALARMS, SET_ALL_OPERATION_ERRORS, SET_ALL_SYSTEM_ERRORS, SET_SYSTEM_ERRORS_FOR_SELECTED_SYSTEM } from "./actions"

const initialState = {
    systemErrors: null,
    systemErrorsForSelectedSystem: null,
    operationErrors: null,
    alarms: null
}

export const errorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_SYSTEM_ERRORS:
            return {
                ...state,
                systemErrors: action.errors
            }
        case SET_SYSTEM_ERRORS_FOR_SELECTED_SYSTEM:
            return {
                ...state,
                systemErrorsForSelectedSystem: action.errors
            }
        case SET_ALL_OPERATION_ERRORS:
            return {
                ...state,
                operationErrors: action.errors
            }
        case SET_ALL_ALARMS:
            return {
                ...state,
                alarms: action.alarms
            }
        default:
            return state
    }
}

export const selectAllSystemErrors = state => {
    return state.errors.systemErrors
}

export const selectSystemErrorsForSelectedSystem = state => {
    return state.errors.systemErrorsForSelectedSystem
}

export const selectAllOperationErrors = state => {
    return state.errors.operationErrors
}

export const selectAllAlarms = state => {
    return state.errors.alarms
}