import CheckIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { displayTime } from '../../utils/time'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const Timeline = ({
    timeline = []
}) => {
    return (
        <div className="flow-root w-full">
            <ul role="list">
                {timeline.map((event, eventIdx) => (
                    <li className="relative" key={event.id}>
                        {eventIdx !== timeline.length - 1 ? (
                            <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                        ) : null}
                        <div className="relative pb-4">
                            <div className="relative flex space-x-3">
                                <div>
                                    <span
                                        className={classNames(
                                            event.status === "Completed" && "bg-green-500",
                                            'h-8 w-8 rounded-full flex items-center justify-center ring-4 ring-white'
                                        )}
                                    >
                                        {
                                            event.status === "Completed" && (
                                                <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                            )
                                        }
                                    </span>
                                </div>
                                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                    <div>
                                        <p className="text-sm text-gray-500">
                                            {event.content}
                                        </p>
                                    </div>
                                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                        {displayTime(event.unix_time)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {event.subSteps.length > 0 && (
                            <div className="relative pb-4">
                                {event.subSteps.map(subStep => (
                                    <>
                                        <div className="flex flex-1 justify-between items-center ml-16 my-2 text-sm text-gray-500">
                                            <div className="flex items-center">
                                                <span
                                                    className={classNames(
                                                        subStep.status === "Completed" && "bg-green-500",
                                                        subStep.status === "Error" && "bg-red-500",
                                                        'mr-2 h-4 w-4 rounded-full flex items-center justify-center'
                                                    )}
                                                >
                                                    {
                                                        subStep.status === "Completed" && (
                                                            <CheckIcon className="h-3 w-3 text-white" aria-hidden="true" />
                                                        )
                                                    }
                                                    {
                                                        subStep.status === "Error" && (
                                                            <ErrorIcon className="h-3 w-3 text-white" aria-hidden="true" />
                                                        )
                                                    }
                                                </span>
                                                <div>
                                                    {subStep.content}
                                                </div>
                                            </div>
                                            <div className="ml-8 text-sm whitespace-nowrap text-gray-500 mr-16">
                                                {displayTime(subStep.unix_time)}
                                            </div>
                                        </div>
                                        {
                                            subStep.data && Object.keys(subStep.data).map(dataKey => (
                                                <div className="flex flex-1 justify-between items-center ml-16 pl-16 my-2 text-sm text-gray-500">
                                                    - {dataKey}: {subStep.data[dataKey]}
                                                </div>
                                            ))
                                        }
                                    </>
                                ))
                                }
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    )
}
