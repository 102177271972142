import { useSelector } from "react-redux"
import get from "lodash/get"
import { SYSTEM_SIDES } from "../../constants/system"
import { selectSystemStateDriverSideFrontKicklift, selectSystemStatePassengerSideFrontKicklift, selectSystemStateDriverSideRearKicklift, selectSystemStatePassengerSideRearKicklift } from "../../ducks/systems/reducer"
import { Text, TextTypes } from "../Typography/Text"
import { TextWithLabel } from "../Typography/TextWithLabel"
import styles from "./SystemState.module.scss"

export const KickliftsInfo = ({ side }) => {
    const frontKickliftInfoSelector = side === SYSTEM_SIDES.DRIVER ? selectSystemStateDriverSideFrontKicklift : selectSystemStatePassengerSideFrontKicklift
    const rearKickliftInfoSelector = side === SYSTEM_SIDES.DRIVER ? selectSystemStateDriverSideRearKicklift : selectSystemStatePassengerSideRearKicklift
    const frontKickliftInfo = useSelector(frontKickliftInfoSelector)
    const rearKickliftInfo = useSelector(rearKickliftInfoSelector)

    return (
        <div className={styles.subNavItemWrapper}>
            <Text type={TextTypes.HEADER_FIVE} additionalClasses={styles.subNavItemTitleOverrides}>Kicklifts</Text>
            <Text type={TextTypes.LABEL} additionalClasses={styles.subNavItemTitleOverrides}>Front</Text>
            <div className={styles.infoSectionWrapper}>
                <TextWithLabel label="Current Location">{get(frontKickliftInfo, "current_location")}</TextWithLabel>
                <TextWithLabel label="Previous Location">{get(frontKickliftInfo, "previous_location")}</TextWithLabel>
                <TextWithLabel label="Current Operation">{get(frontKickliftInfo, "current_operation")}</TextWithLabel>
                <TextWithLabel label="Execution State">{get(frontKickliftInfo, "execution_state")}</TextWithLabel>
                <TextWithLabel label="Operation Success Counter">{get(frontKickliftInfo, "operation_success_counter")}</TextWithLabel>
            </div>
            <Text type={TextTypes.LABEL} additionalClasses={styles.subNavItemTitleOverrides}>Rear</Text>
            <div className={styles.infoSectionWrapper}>
                <TextWithLabel label="Current Location">{get(rearKickliftInfo, "current_location")}</TextWithLabel>
                <TextWithLabel label="Previous Location">{get(rearKickliftInfo, "previous_location")}</TextWithLabel>
                <TextWithLabel label="Current Operation">{get(rearKickliftInfo, "current_operation")}</TextWithLabel>
                <TextWithLabel label="Execution State">{get(rearKickliftInfo, "execution_state")}</TextWithLabel>
                <TextWithLabel label="Operation Success Counter">{get(rearKickliftInfo, "operation_success_counter")}</TextWithLabel>
            </div>
        </div>
    )
}