import clsx from "clsx"
import styles from "./Typography.module.scss"

export const TextTypes = Object.freeze({
    HEADER_ONE: "headerOne",
    HEADER_TWO: "headerTwo",
    HEADER_THREE: "headerThree",
    HEADER_FOUR: "headerFour",
    HEADER_FIVE: "headerFive",
    HEADER_SIX: "headerSix",
    SUBTITLE_ONE: "subtitleOne",
    SUBTITLE_TWO: "subtitleTwo",
    BODY_ONE: "bodyOne",
    BODY_TWO: "bodyTwo",
    LABEL: "label"
})

export const Text = ({
    additionalClasses,
    children,
    type = TextTypes.BODY_ONE,
    color = "black",
    ...rest
}) => {
    return (
        <div className={clsx(additionalClasses, styles[type])} {...rest}>
            {children}
        </div>
    )
}