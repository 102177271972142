import { useSelector } from "react-redux"
import get from "lodash/get"
import { SYSTEM_SIDES } from "../../constants/system"
import { selectSystemStateDriverSideRobot, selectSystemStatePassengerSideRobot } from "../../ducks/systems/reducer"
import { Text, TextTypes } from "../Typography/Text"
import { TextWithLabel } from "../Typography/TextWithLabel"
import styles from "./SystemState.module.scss"

export const RobotInfo = ({ side }) => {
    const robotInfoSelector = side === SYSTEM_SIDES.DRIVER ? selectSystemStateDriverSideRobot : selectSystemStatePassengerSideRobot
    const robotInfo = useSelector(robotInfoSelector)
    const robot = get(robotInfo, "robot")
    const robotCurrentPose = get(robot, "current_pose")
    const gripper = get(robotInfo, "gripper")
    const nutRunner = get(robotInfo, "nut_runner")

    return (
        <div className={styles.subNavItemWrapper}>
            <Text type={TextTypes.HEADER_FIVE} additionalClasses={styles.subNavItemTitleOverrides}>Robot Subsystem</Text>
            <div className={styles.infoSectionWrapper}>
                <TextWithLabel label="Current Operation">{get(robotInfo, "current_operation")}</TextWithLabel>
                <TextWithLabel label="Execution State">{get(robotInfo, "execution_state")}</TextWithLabel>
                <TextWithLabel label="Operation Success Counter">{get(robotInfo, "operation_success_counter")}</TextWithLabel>
            </div>
            <Text type={TextTypes.HEADER_SIX} additionalClasses={styles.subNavItemTitleOverrides}>Robot</Text>
            <div className={styles.infoSectionWrapper}>
                <TextWithLabel label="Current Location">{get(robot, "current_location")}</TextWithLabel>
                <TextWithLabel label="Previous Location">{get(robot, "previous_location")}</TextWithLabel>
                <TextWithLabel label="Current Operation">{get(robot, "current_operation")}</TextWithLabel>
                <TextWithLabel label="Execution State">{get(robot, "execution_state")}</TextWithLabel>
                <TextWithLabel label="Operation Success Counter">{get(robot, "operation_success_counter")}</TextWithLabel>
            </div>
            <Text type={TextTypes.LABEL} additionalClasses={styles.subNavItemTitleOverrides}>Current Pose</Text>
            <div className={styles.infoSectionWrapper}>
                <TextWithLabel label="A">{get(robotCurrentPose, "A")}</TextWithLabel>
                <TextWithLabel label="O">{get(robotCurrentPose, "O")}</TextWithLabel>
                <TextWithLabel label="T">{get(robotCurrentPose, "T")}</TextWithLabel>
                <TextWithLabel label="X">{get(robotCurrentPose, "X")}</TextWithLabel>
                <TextWithLabel label="Y">{get(robotCurrentPose, "Y")}</TextWithLabel>
                <TextWithLabel label="Z">{get(robotCurrentPose, "Z")}</TextWithLabel>
            </div>
            <Text type={TextTypes.HEADER_SIX} additionalClasses={styles.subNavItemTitleOverrides}>Gripper</Text>
            <div className={styles.infoSectionWrapper}>
                <TextWithLabel label="Current Operation">{get(gripper, "current_operation")}</TextWithLabel>
                <TextWithLabel label="Execution State">{get(gripper, "execution_state")}</TextWithLabel>
                <TextWithLabel label="Execution Diameter">{get(gripper, "current_diameter")}</TextWithLabel>
                <TextWithLabel label="Has Rubber">{get(gripper, "has_rubber")}</TextWithLabel>
                <TextWithLabel label="Has Wheel">{get(gripper, "has_wheel")}</TextWithLabel>
                <TextWithLabel label="Wheel Index">{get(gripper, "wheel_index")}</TextWithLabel>
                <TextWithLabel label="Operation Success Counter">{get(gripper, "operation_success_counter")}</TextWithLabel>
            </div>
            <Text type={TextTypes.HEADER_SIX} additionalClasses={styles.subNavItemTitleOverrides}>Nut Runner</Text>
            <div className={styles.infoSectionWrapper}>
                <TextWithLabel label="Current Operation">{get(nutRunner, "current_operation")}</TextWithLabel>
                <TextWithLabel label="Execution State">{get(nutRunner, "execution_state")}</TextWithLabel>
                <TextWithLabel label="Nut ID">{get(nutRunner, "nut_id")}</TextWithLabel>
                <TextWithLabel label="Operation Success Counter">{get(nutRunner, "operation_success_counter")}</TextWithLabel>
            </div>
        </div>
    )
}