import clsx from "clsx";
import map from "lodash/map";
import { useSelector } from "react-redux";
import { selectAlertsForSelectedSystem } from "../../ducks/systems/reducer";
import { Card } from "../Card/Card";
import { Text, TextTypes } from "../Typography/Text";
import { Alert } from "./Alert";
import styles from "./SystemState.module.scss";

export const AlertsCard = () => {
  const { criticalAlerts, nonCriticalAlerts, visionAlerts } = useSelector(
    selectAlertsForSelectedSystem
  );

  return (
    <Card additionalClasses={styles.cardOverrides}>
      <Text
        type={TextTypes.HEADER_FIVE}
        additionalClasses={styles.titleOverrides}
      >
        Alerts
      </Text>
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={clsx(
          styles.titleOverrides,
          styles.alarmTitleOverrides
        )}
      >
        Critical Alerts - {criticalAlerts.length}
      </Text>
      {map(criticalAlerts, (alert) => (
        <Alert alert={alert} />
      ))}
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={clsx(
          styles.titleOverrides,
          styles.alarmTitleOverrides
        )}
      >
        Vision Alerts - {visionAlerts.length}
      </Text>
      {map(visionAlerts, (alert) => (
        <Alert alert={alert} />
      ))}
      <Text
        type={TextTypes.HEADER_SIX}
        additionalClasses={clsx(
          styles.titleOverrides,
          styles.alarmTitleOverrides
        )}
      >
        Notifications - {nonCriticalAlerts.length}
      </Text>
      {map(nonCriticalAlerts, (alert) => (
        <Alert alert={alert} />
      ))}
    </Card>
  );
};
