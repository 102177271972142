import { useSelector } from "react-redux";
import { selectIsCustomersLoading } from "../../ducks/customers/reducer";
import { ActiveSystemsList } from "../ActiveSystemsCard/ActiveSystemsCard";
import { CustomersList } from "../Customers/CustomersList";
import { RouteWrapper } from "../Layout/RouteWrapper";

export const Home = () => {
  const shouldShowLoader = useSelector(selectIsCustomersLoading);

  return (
    <RouteWrapper shouldShowLoader={shouldShowLoader}>
      <ActiveSystemsList />
      <CustomersList />
    </RouteWrapper>
  );
};
