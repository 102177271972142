import { useSelector } from "react-redux"
import get from "lodash/get"
import { SYSTEM_SIDES } from "../../constants/system"
import { selectSystemStateDriverSideBalancer, selectSystemStatePassengerSideBalancer } from "../../ducks/systems/reducer"
import { Text, TextTypes } from "../Typography/Text"
import { TextWithLabel } from "../Typography/TextWithLabel"
import styles from "./SystemState.module.scss"

export const BalancerInfo = ({ side }) => {
    const balancerInfoSelector = side === SYSTEM_SIDES.DRIVER ? selectSystemStateDriverSideBalancer : selectSystemStatePassengerSideBalancer
    const balancerInfo = useSelector(balancerInfoSelector)
    const balancerBasket = get(balancerInfo, "basket")

    return (
        <div className={styles.subNavItemWrapper}>
            <Text type={TextTypes.HEADER_FIVE} additionalClasses={styles.subNavItemTitleOverrides}>Balancer</Text>
            <div className={styles.infoSectionWrapper}>
                <TextWithLabel label="Online">{get(balancerInfo, "online")}</TextWithLabel>
                <TextWithLabel label="Online">{get(balancerInfo, "current_wheel_assembly")}</TextWithLabel>
                <TextWithLabel label="Assembly Loaded">{get(balancerInfo, "assembly_loaded")}</TextWithLabel>
                <TextWithLabel label="Command Completed">{get(balancerInfo, "command_completed")}</TextWithLabel>
            </div>
            <Text type={TextTypes.HEADER_SIX} additionalClasses={styles.subNavItemTitleOverrides}>Balancer Basket</Text>
            <div className={styles.infoSectionWrapper}>
                <TextWithLabel label="Current Location">{get(balancerBasket, "current_location")}</TextWithLabel>
                <TextWithLabel label="Previous Location">{get(balancerBasket, "previous_location")}</TextWithLabel>
                <TextWithLabel label="Current Operation">{get(balancerBasket, "current_operation")}</TextWithLabel>
                <TextWithLabel label="Execution State">{get(balancerBasket, "execution_state")}</TextWithLabel>
                <TextWithLabel label="Operation Success Counter">{get(balancerBasket, "operation_success_counter")}</TextWithLabel>
                <TextWithLabel label="Loaded">{get(balancerBasket, "loaded")}</TextWithLabel>
                <TextWithLabel label="Wheel Contained">{get(balancerBasket, "wheel_contained")}</TextWithLabel>
            </div>
        </div>
    )
}