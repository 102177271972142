import get from "lodash/get"
import { SET_CONFIG } from "./actions"

const initialState = {
    config: null
}

export const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONFIG:
            return {
                ...state,
                config: action.config
            }
        default:
            return state
    }
}

export const selectConfig = state => {
    return get(state, "config.config")
}
