import { useSelector } from 'react-redux'
import { selectIsCustomersLoading } from '../../ducks/customers/reducer'
import { CustomersList } from '../Customers/CustomersList'
import { RouteWrapper } from '../Layout/RouteWrapper'

export const CustomersRoute = () => {
    const shouldShowLoader = useSelector(selectIsCustomersLoading)

    return (
        <RouteWrapper
            shouldShowLoader={shouldShowLoader}
        >
            <CustomersList />
        </RouteWrapper>
    )
}
