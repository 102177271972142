import get from "lodash/get"
import { MODALS } from "../../constants/modals"
import { SHOW_MODAL, HIDE_MODAL } from "./actions"

const initialState = {
    modal: null
}

export const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                modal: action.modal
            }
        case HIDE_MODAL:
            return {
                ...state,
                modal: null
            }
        default:
            return state
    }
}

export const selectIsAddNewCustomerModalShowing = state => {
    return get(state, "modal.modal") === MODALS.ADD_NEW_CUSTOMER
}

export const selectIsAddNewEquipmentModalShowing = state => {
    return get(state, "modal.modal") === MODALS.ADD_NEW_EQUIPMENT
}

export const selectIsAddNewLocationModalShowing = state => {
    return get(state, "modal.modal") === MODALS.ADD_NEW_LOCATION
}

export const selectIsAddNewSystemModalShowing = state => {
    return get(state, "modal.modal") === MODALS.ADD_NEW_SYSTEM
}

export const selectIsAddNewUserModalShowing = state => {
    return get(state, "modal.modal") === MODALS.ADD_NEW_USER
}
