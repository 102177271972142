import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import get from "lodash/get";
import {
  selectIsGetSystemLoading,
  selectSelectedSystemId,
} from "../../ducks/systems/reducer";
import {
  getSystem,
  getSystemState,
  setSelectedSystem,
  setSelectedSystemId,
} from "../../ducks/systems/actions";
import { useEffect } from "react";
import { SystemInfo } from "../SystemInfo/SystemInfo";
import { OperationsList } from "../Operations/OperationsList";
import { selectIsGetOperationsLoading } from "../../ducks/operations/reducer";
import { RouteWrapper } from "../Layout/RouteWrapper";
import { SystemStatus } from "../SystemStatus/SystemStatus";

export const SystemRoute = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const systemId = get(params, "id");
  const selectedSystemId = useSelector(selectSelectedSystemId);
  const isGetSystemLoading = useSelector(selectIsGetSystemLoading);
  const isGetJobsLoading = useSelector(selectIsGetOperationsLoading);
  const shouldShowLoader = isGetSystemLoading || isGetJobsLoading;

  useEffect(() => {
    dispatch(setSelectedSystemId(systemId));

    dispatch(getSystem(systemId));
    dispatch(getSystemState(systemId));

    return () => {
      dispatch(setSelectedSystem(null));
      dispatch(setSelectedSystemId(null));
    };
  }, [systemId]);

  return (
    <RouteWrapper shouldShowLoader={shouldShowLoader}>
      <SystemInfo showCurrentStatus={true} />
      <SystemStatus />
      <OperationsList />
    </RouteWrapper>
  );
};
