import clsx from "clsx"
import React from "react"
import styles from "./Buttons.module.scss"

export const Link = ({
    additionalClasses,
    children,
    onClick
}) => {
    return (
        <div className={clsx(styles.link, additionalClasses)} onClick={onClick}>
            {children}
        </div>
    )
}