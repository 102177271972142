import { filter, get } from "lodash";
import { jobTimelineOne } from "../../mockData/jobTimeline-1";
import { jobTimelineTwo } from "../../mockData/jobTimeline-2";
import { getMockJobs } from "../../mockData/mockJobs";
import { apiRequest } from "../../utils/apiRequest";

export const SET_OPERATIONS = "SET_OPERATIONS";
export const SET_COMPLETED_OPERATIONS = "SET_COMPLETED_OPERATIONS";
export const SET_IS_GET_COMPLETED_OPERATIONS_LOADING =
  "SET_IS_GET_COMPLETED_OPERATIONS_LOADING";
export const SET_IS_GET_OPERATIONS_LOADING = "SET_IS_GET_OPERATIONS_LOADING";
export const SET_IS_GET_OPERATION_DETAILS_LOADING =
  "SET_IS_GET_OPERATION_DETAILS_LOADING";
export const SET_OPERATION_DETAILS = "SET_OPERATION_DETAILS";

export const setOperations = (operations) => {
  return {
    type: SET_OPERATIONS,
    operations,
  };
};

export const setCompletedOperations = (completedOperations) => {
  return {
    type: SET_COMPLETED_OPERATIONS,
    completedOperations,
  };
};

export const setIsCompletedOperationsLoading = (isLoading) => {
  return {
    type: SET_IS_GET_COMPLETED_OPERATIONS_LOADING,
    isLoading,
  };
};

export const setIsGetOperationsLoading = (isLoading) => {
  return {
    type: SET_IS_GET_OPERATIONS_LOADING,
    isLoading,
  };
};

export const setOperationDetails = (operationDetails) => {
  return {
    type: SET_OPERATION_DETAILS,
    operationDetails,
  };
};

export const setIsGetOperationDetailsLoading = (isLoading) => {
  return {
    type: SET_IS_GET_OPERATIONS_LOADING,
    isLoading,
  };
};

export const getOperationsForId = (id) => {
  return (dispatch) => {
    dispatch(setIsGetOperationsLoading(true));
    return apiRequest({
      method: "GET",
      endpoint: `systems/${id}/operations/`,
    })
      .then((operations) => {
        dispatch(setIsGetOperationsLoading(false));
        dispatch(setOperations(operations.data));
      })
      .catch((error) => {
        dispatch(setIsGetOperationsLoading(false));
        console.log("Get operations error: ", error);
      });
  };
};

export const getCompletedJobsForId = (system_id) => {
  return (dispatch) => {
    dispatch(setIsCompletedOperationsLoading(true));
    return apiRequest({
      method: "GET",
      endpoint: `system_state/${system_id}/completed_jobs`,
    })
      .then((operations) => {
        dispatch(setIsCompletedOperationsLoading(false));
        dispatch(setCompletedOperations(operations.data));
      })
      .catch((error) => {
        dispatch(setIsCompletedOperationsLoading(false));
        console.log("Get operations error: ", error);
      });
  };
};

export const getOperationDetails = (operationId) => {
  return (dispatch) => {
    dispatch(setIsGetOperationDetailsLoading(true));
    return apiRequest({
      method: "GET",
      endpoint: `operations/${operationId}/operation_info`,
    })
      .then((operationDetailsResponse) => {
        dispatch(setIsGetOperationDetailsLoading(false));
        dispatch(setOperationDetails(operationDetailsResponse.data));
      })
      .catch((error) => {
        dispatch(setIsGetOperationDetailsLoading(false));
        console.log("Get operation details error: ", error);
      });
  };
};
