import clsx from "clsx"
import React from "react"
import styles from "./Card.module.scss"

export const Card = ({
    children,
    additionalClasses
}) => {
    return (
        <div className={clsx(styles.cardWrapper, additionalClasses)} onClick={e => e.stopPropagation()}>
            {children}
        </div>
    )
}