import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSystems } from '../../ducks/systems/actions'
import { selectIsGetSystemsLoading, selectSystems } from '../../ducks/systems/reducer'
import { showModal } from '../../ducks/modal/actions'
import { MODALS } from '../../constants/modals'
import { ListCard } from '../ListCard/ListCard'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../constants/routes'

export const SystemsList = ({ AdditionalContent, customizedActions, queryParams = {} }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const systems = useSelector(selectSystems)

    useEffect(() => {
        dispatch(getSystems(queryParams))
    }, [])

    const handleAddNewSystem = () => {
        dispatch(showModal(MODALS.ADD_NEW_SYSTEM))
    }

    return (
        <ListCard
            title="Systems"
            tableActions={[{
                title: "Add New System",
                onClick: handleAddNewSystem
            }]}
            AdditionalContent={AdditionalContent}
            tableProps={{
                headers: [
                    { title: "ID", dataKey: "id" },
                    { title: "Customer", dataKey: "customer" },
                    { title: "Location", dataKey: "location" },
                    { title: "Status", dataKey: "status" }
                ],
                data: systems,
                additionalCellProps:
                    ({ currentItem, item }) => {
                        if (currentItem === "location") {
                            return {
                                renderValue: () => <>{item.location?.city}, {item.location?.state}</>
                            }
                        }
                        if (currentItem === "customer") {
                            return {
                                renderValue: () => <>{item.customer.name}</>
                            }
                        }
                    },
                actions: customizedActions ?
                    customizedActions
                    :
                    [{
                        id: "system",
                        title: "View System",
                        onClick: (item) => {
                            navigate(`${ROUTES.SYSTEMS}/${item.id}`)
                        }
                    }]
            }}
        />
    )
}

