import { SET_ALL_EQUIPMENT } from "./actions"

const initialState = {
    allEquipment: [],
}

export const equipmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_EQUIPMENT:
            return {
                ...state,
                allEquipment: action.equipment
            }
        default:
            return state
    }
}

export const selectAllEquipment = state => {
    return state.equipment.allEquipment
}