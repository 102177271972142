import { SET_VEHICLE_DETAILS_FOR_OPERATION } from "./actions"

const initialState = {
    vehicles: [],
    vehicleDetailsForSelectedOperation: null
}

export const vehiclesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VEHICLE_DETAILS_FOR_OPERATION:
            return {
                ...state,
                vehicleDetailsForSelectedOperation: action.vehicleDetails
            }
        default:
            return state
    }
}

export const selectVehicleDetailsForSelectedOperation = state => {
    return state.vehicles.vehicleDetailsForSelectedOperation
}