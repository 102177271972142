import get from "lodash/get"
import { SET_ACTIVE_LIVESTREAM, SET_LIVESTREAMS } from "./actions"

const initialState = {
    livestreams: [],
    activeLivestream: null
}

export const livestreamReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LIVESTREAMS:
            return {
                ...state,
                livestreams: action.livestreams
            }
        case SET_ACTIVE_LIVESTREAM:
            return {
                ...state,
                activeLivestream: action.livestream
            }
        default:
            return state
    }
}

export const selectAllLivestreams = state => {
    return state.livestream.livestreams
}

export const selectActiveLivestreamUrl = state => {
    return get(state, "livestream.activeLivestream.url")
}

export const selectActiveLivestreamId = state => {
    return get(state, "livestream.activeLivestream.livestream_id")
}