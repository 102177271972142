import { useSelector } from "react-redux";
import get from "lodash/get";
import map from "lodash/map";
import {
  selectLiftFromSystemState,
  selectVehicleFromSystemState,
} from "../../ducks/systems/reducer";
import { Card } from "../Card/Card";
import { Text, TextTypes } from "../Typography/Text";
import { translateKey } from "../../utils/translations";
import styles from "./SystemState.module.scss";

export const PositioningAndLiftCard = () => {
  const pullInData = useSelector(selectVehicleFromSystemState);
  const pullInDataFieldsToDisplay = [
    "correct_pull_in_distance",
    "position_from_center",
    "ideal_pull_in_distance",
  ];
  const liftData = useSelector(selectLiftFromSystemState);
  const liftDataFieldsToDisplay = [
    "current_height",
    "target_height",
    "current_location",
  ];

  return (
    <Card additionalClasses={styles.cardOverrides}>
      <Text
        type={TextTypes.HEADER_FIVE}
        additionalClasses={styles.titleOverrides}
      >
        Pull In Data
      </Text>
      {map(pullInDataFieldsToDisplay, (field) => (
        <div className={styles.column} key={field}>
          <Text type={TextTypes.LABEL}>{translateKey(field)}</Text>
          <Text
            type={TextTypes.BODY_ONE}
            additionalClasses={styles.contentOverrides}
          >{`${get(pullInData, field)}`}</Text>
        </div>
      ))}
      <Text
        type={TextTypes.HEADER_FIVE}
        additionalClasses={styles.titleOverrides}
      >
        Lift Data
      </Text>
      {map(liftDataFieldsToDisplay, (field) => (
        <div className={styles.column} key={field}>
          <Text type={TextTypes.LABEL}>{translateKey(field)}</Text>
          <Text
            type={TextTypes.BODY_ONE}
            additionalClasses={styles.contentOverrides}
          >{`${get(liftData, field)}`}</Text>
        </div>
      ))}
    </Card>
  );
};
