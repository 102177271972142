import jwtDecode from "jwt-decode"

export const checkForCookie = () => {
    if (document.cookie.split(';').some((item) => item.trim().startsWith('auth_token='))) {
        const correctCookie = document.cookie.split(';').find((item) => item.trim().startsWith('auth_token=')) || ""
        const trimmedCookie = correctCookie.trim()
        const token = trimmedCookie.split("=")[1]
        const decodedToken = jwtDecode(token)
        if (decodedToken.exp < new Date() / 1000) {
            console.log("Token Expired.")
            return false
        }
        return true
    }
    else {
        return false
    }
}

export const setCookie = (token) => {
    document.cookie = `auth_token=Bearer ${token}; SameSite=Strict`
}

export const getCookie = () => {
    const correctCookie = document.cookie.split(';').find((item) => item.trim().startsWith('auth_token=')) || ""
    const trimmedCookie = correctCookie.trim()
    const splitCookie = trimmedCookie.split("=")[1]

    return splitCookie
}