import { SET_LOCATIONS, SET_SELECTED_LOCATION, SET_LOCATIONS_FOR_SELECTED_CUSTOMER } from "./actions"

const initialState = {
    locations: [],
    selectedLocation: {},
    locationsForSelectedCustomer: []
}

export const locationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOCATIONS:
            return {
                ...state,
                locations: action.locations
            }
        case SET_SELECTED_LOCATION:
            return {
                ...state,
                selectedLocation: action.location
            }
        case SET_LOCATIONS_FOR_SELECTED_CUSTOMER:
            return {
                ...state,
                locationsForSelectedCustomer: action.locations
            }
        default:
            return state
    }
}

export const selectAllLocations = (state) => {
    return state.locations.locations
}

export const selectSelectedLocation = (state) => {
    return state.locations.selectedLocation
}

export const selectLocationsForSelectedCustomer = (state) => {
    return state.locations.locationsForSelectedCustomer
}